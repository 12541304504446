/* --------------------------- Original Styles --------------------------- */
.inventory-trade-form { 
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.inventory-trade-form h2 {
  text-align: center;
  color: var(--sales-form-text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.inventory-trade-form h3 {
  cursor: pointer;
  color: var(--section-header-color); /* Use CSS Variable */
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Smooth transition */
}

.filters-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.filters-container button {
  background: var(--input-border-color);
  margin: auto;
}

.trade-compare-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.trade-row {
  display: flex;
  gap: 1rem;
  border: 1px solid var(--box-shadow-color);
  padding: 1rem;
  border-radius: 10px;
}
.trade-row.headers-row {
  border: none;
  margin-bottom: -20px;
}

/* .headers-row {
  background: var(--header-background-color);
} */

.header-col {
  text-align: center;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
}

.trade-col {
  flex: 1;
  background: var(--sales-report-table-row-even-background-color);
  border-radius: 10px;
  padding: 0.5rem;
}

.external-local-inventory{
  flex: 1;
  background: var(--background-color);
  border-radius: 10px;
  padding: 0.5rem;
}

.external-vehicle{
  flex: 1;
  background: var(--background-color);
  border-radius: 10px;
  padding: 0.5rem;
}

.external-local-inventory .trade-row{
  padding: 15px;
  margin-bottom: 10px;
}

.images-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.images-container img {
  max-width: 160px;
  max-height: 120px;
  object-fit: cover;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  background: #fff;
}

.images-container button {
  color: var(--button-text-color);
  background: var(--input-border-color);
}

.difference-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  background: var(--sales-report-table-row-odd-background-color);
}

button {
  cursor: pointer;
  /* border: 1px solid var(--button-border-color); */
  background: var(--button-background-color);
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
}

.focused-comparison {
  /* border: 1px solid var(--box-shadow-color); */
  margin-bottom: 1rem;
  padding: 1rem;
  background: var(--sales-report-table-row-odd-background-color);
  border-radius: 10px;
  box-shadow: var(--form-box-shadow); /* Use CSS Variable */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.focused-comparison-row {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
}

.focused-vehicle {
  flex: 1;
  margin: 0 1rem;
}

.focused-differences {
  min-width: 150px;
  text-align: center;
  background: var(--sales-report-table-row-even-background-color);
  border-radius: 10px;
  padding: 0.5rem;
}

.focused-image-container img {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 10px;
}

.vehicle-header {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
}

/* Highlight the selected row */
.selected-vehicle {
  background-color: rgba(52, 152, 219, 0.1);
  border: 2px solid #3498db;
  transition: background-color 0.3s ease, border 0.3s ease;
}

/* Optional: Style for header columns */
.header-col {
  /* background-color: var(--header-background-color); */
  padding: 0.75rem 0;
  border-bottom: 2px solid var(--box-shadow-color);
}

/* --------------------- New Styles for Additional Features --------------------- */

.trade-type-toggle {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.trade-type-toggle button {
  padding: 0.5rem 1rem;
  /* border: 1px solid var(--input-border-color); */
  background: var(--button-background-color);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  font-size: 1rem;
}

.trade-type-toggle button.active {
  background: var(--section-header-color);
  color: var(--button-text-color);
  border-color: var(--section-header-color);
}

/* Optional: Styling for icons within the toggle (if used) */
.trade-type-toggle .icon {
  margin-right: 0.5rem;
}

/* Inline Search Fields (Internal & External) */
.internal-inventory-filter,
.external-inventory-filter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.internal-inventory-filter input,
.external-inventory-filter input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  background: var(--background-color);
}

/* Pagination - Horizontal Numbered Pagination */
.pagination-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}
.pagination-numbers button {
  cursor: pointer;
  border: 1px solid var(--input-border-color);
  background: var(--button-background-color);
  padding: 0.25rem 0.75rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, border 0.3s ease;
}
.pagination-numbers button:hover {
  background: var(--input-border-color);
}
.pagination-numbers span {
  display: inline-block;
  padding: 0.25rem 0.75rem;
}
.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

/* External Dealer Trade Section */
.external-fields-container {
  background: var(--sales-report-table-row-even-background-color);
  border: 1px solid var(--box-shadow-color);
  border-radius: 10px;
  padding: 1rem;
  margin-bottom: 1rem;
}
.external-fields-container h3 {
  text-align: center;
  color: var(--section-header-color);
}

/* Styling for select and text inputs (applies to new fields as well) */
select,
input[type="text"] {
  padding: 0.5rem;
  border: 1px solid var(--input-border-color);
  border-radius: 4px;
  background: var(--background-color);
}

/* ---------------------------- End of New Styles ---------------------------- */
