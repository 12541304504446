/* CustomerLogForm.css */

.customer-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  color: var(--form-text-color); /* Use CSS Variable */
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: var(--form-background-color); /* Use CSS Variable */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow remains unchanged */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

button, .centered-button {
  padding: 10px 20px;
  margin: 10px auto;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid var(--button-border-color); /* Use CSS Variable */
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border-radius: 4px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: inline-block;
}

button:hover, .centered-button:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
  border-color: var(--button-hover-border-color); /* Use CSS Variable */
}

button:focus, .centered-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Optional: Add focus styles for accessibility */
}

.search-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Add space between the Search and Clear Form buttons */
  margin-top: 10px; /* Optional: Add space above the button container */
}

/* Style for the search input */
.search-section .search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--input-border-color); /* Use CSS Variable */
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--search-input-background-color); /* Use CSS Variable */
  color: var(--search-input-text-color); /* Use CSS Variable */
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

/* Optional: Add focus styles for the search input */
.search-section .search-input:focus {
  border-color: var(--button-background-color); /* Use button color for focus */
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Light blue focus shadow */
}

/* Form fields container */
.form-fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

/* Form groups for form fields */
.form-group {
  width: 48%; /* Two columns with some space between */
  box-sizing: border-box;
  margin-bottom: 20px;
}

/* Full-width form groups */
.form-group.full-width {
  width: 100%;
}

/* Ensure labels and inputs take full width within form-group */
.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--form-text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="number"],
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--input-border-color); /* Use CSS Variable */
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-background-color); /* Use CSS Variable */
  color: var(--form-text-color); /* Use CSS Variable for text */
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

/* Custom styles for React Select */
.custom-react-select .react-select__control {
  background-color: var(--input-background-color) !important;
  border-color: var(--input-border-color) !important;
  box-shadow: none !important;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.custom-react-select .react-select__control:hover {
  border-color: var(--button-background-color) !important;
}

.custom-react-select .react-select__menu {
  background-color: var(--form-background-color) !important;
  color: var(--form-text-color) !important;
}

.custom-react-select .react-select__option--is-focused {
  background-color: var(--button-hover-background-color) !important;
  color: var(--form-text-color) !important;
}

.custom-react-select .react-select__option--is-selected {
  background-color: var(--button-hover-background-color) !important;
  color: var(--form-text-color) !important;
}

.custom-react-select .react-select__single-value {
  color: var(--form-text-color) !important;
}

.custom-react-select .react-select__placeholder {
  color: var(--search-input-text-color) !important;
}

.custom-react-select .react-select__input {
  color: var(--form-text-color) !important;
}

/* Styles for checkboxes */
.form-group.checkbox-group {
  width: 48%;
  display: flex;
  align-items: center;
}

.form-group.checkbox-group label {
  display: flex;
  align-items: center;
  color: var(--form-text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

/* Increase the size of checkboxes and apply accent color */
.form-group.checkbox-group input[type="checkbox"] {
  transform: scale(1.5);
  -ms-transform: scale(1.5); /* IE 9 */
  -webkit-transform: scale(1.5); /* Safari 3-8 */
  margin-right: 10px; /* Adjust spacing as needed */
  accent-color: var(--checkbox-accent-color); /* Use CSS Variable for checkbox color */
  transition: accent-color 0.3s ease;
}

/* Form actions (Submit button container) */
.form-actions {
  width: 100%;
  text-align: center; /* Center the button horizontally */
  margin-top: 20px; /* Add space above the button */
}

.form-actions .centered-button {
  width: auto;
  padding: 10px 20px;
}

/* Style for the fetched-data section */
.fetched-data {
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: var(--fetched-data-background-color); /* Use CSS Variable */
  color: var(--form-text-color); /* Use CSS Variable */
  border: 1px solid var(--fetched-data-border-color); /* Use CSS Variable */
  border-radius: 4px;
  box-sizing: border-box;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

/* Optional: Style the headings and paragraphs within fetched-data */
.fetched-data h3 {
  margin-bottom: 10px;
  font-size: 1.25rem;
  color: inherit; /* Inherit color from parent */
  transition: color 0.3s ease;
}

.fetched-data p {
  margin: 5px 0;
  line-height: 1.5;
  color: inherit; /* Inherit color from parent */
  transition: color 0.3s ease;
}

.fetched-data strong {
  color: inherit; /* Inherit color from parent */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .form-group {
    width: 100%;
  }

  button, .centered-button {
    width: 100%;
    margin: 5px 0;
    padding: 12px 0; /* Optional: Adjust padding for better touch targets on mobile */
  }

  .search-buttons {
    flex-direction: column;
  }

  .search-buttons .centered-button {
    width: 100%;
    margin-bottom: 10px;
  }
}
