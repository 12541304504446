/* src/pages/PerformancePlanData.css */

/* Container Styling */
.performance-plan-container {
    max-width: 900px;
    margin: 40px auto;
    padding: 25px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--background-color);
    border-radius: 12px;
    box-shadow: 0 4px 12px var(--box-shadow-color);
}

/* Toggle Fetch Section Button */
.toggle-fetch-button {
    background-color: #6c757d;
    color: #ffffff;
    padding: 10px 16px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.toggle-fetch-button:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
}

.toggle-fetch-button:active {
    background-color: #545b62;
    transform: translateY(0);
}

/* Fetch Data Section */
.fetch-data-section {
    background-color: var(--chart-background-color);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 2px 8px var(--box-shadow-color);
}

.fetch-data-section h2 {
    margin-bottom: 15px;
    color: var(--text-color);
    font-size: 22px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.form-group {
    flex: 1 1 200px;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: 600;
    color: var(--button-text-color);
    font-size: 14px;
}

.form-group select,
.form-group input[type="number"] {
    padding: 10px 12px;
    font-size: 16px;
    border: 1px solid var(--table-border-color);
    border-radius: 6px;
    background-color: var(--side-nav-background-color);
    transition: border-color 0.3s, box-shadow 0.3s;
}

.form-group select:focus,
.form-group input[type="number"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.form-group select {
    appearance: none;
    background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5"><path fill="%23999" d="M0 0l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px 5px;
    cursor: pointer;
}

/* Fetch and Clear Buttons Container */
.fetch-buttons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

/* Fetch Button */
.get-button {
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    /* padding: 12px 20px; */
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    flex: 1;
}

.get-button:hover {
    background-color: var(--toggle-button-hover-background-color);
    transform: translateY(-2px);
}

.get-button:active {
    background-color: var(--toggle-button-hover-background-color);
    transform: translateY(0);
}

/* Clear Button */
.clear-button {
    background-color: #6c757d;
    color: #ffffff;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    flex: 1;
}

.clear-button:hover {
    background-color: #bd2130;
    transform: translateY(-2px);
}

.clear-button:active {
    background-color: #bd2130;
    transform: translateY(0);
}

/* Fetched Data Table */
.fetched-data-table {
    background-color: var(--background-color);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 2px 8px var(--box-shadow-color);
}

.fetched-data-table h2 {
    margin-bottom: 15px;
    color: var(--leaderboard-table-header-text-color);
    font-size: 22px;
}

.fetched-data-table table {
    width: 100%;
    border-collapse: collapse;
}

.fetched-data-table th,
.fetched-data-table td {
    padding: 12px 15px;
    border: 1px solid var(--table-header-background-color);
    text-align: left;
    color: var(--text-color);
}

.fetched-data-table th {
    background-color: var(--leaderboard-table-row-odd-background-color);
    font-weight: 600;
}

.fetched-data-table tr:nth-child(even) {
    background-color: var(--leaderboard-table-row-even-background-color);
}

.table-row {
    cursor: pointer;
    transition: background-color 0.3s;
}

.table-row:hover {
    background-color: var(--leaderboard-table-row-hover-background-color);
}

.note {
    font-size: 12px;
    color: #0069d9;
    margin-top: 10px;
}

/* Submission Form */
.performance-plan-form {
    background-color: var(--chart-background-color);
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 2px 8px var(--box-shadow-color);
    margin-bottom: 30px;
}

.performance-plan-form h2 {
    margin-bottom: 20px;
    color: var(--text-color);
    font-size: 22px;
}

.entry-group {
    margin-bottom: 25px;
    padding: 20px;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    background-color: var(--chart-background-color);
    position: relative;
}

.entry-group h3 {
    margin-top: 0;
    margin-bottom: 15px;
    color: var(--text-color);
    font-size: 18px;
    border-bottom: 1px solid var(--sales-report-section-heading-border-color);
    padding-bottom: 5px;
}

.remove-button {
    position: absolute;
    top: 10px;
    right: 20px;
    width: auto;
    background-color: #dc3545;
    color: var(--button-text-color);
    font-size: 14px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
}

.remove-button:hover {
    background-color: #c82333;
    transform: translateY(-1px);
}

.remove-button:active {
    background-color: #bd2130;
    transform: translateY(0);
}

.add-button {
    background-color: #6c757d;
    color: #ffffff;
    padding: 14px 28px;
    font-size: 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    /* margin-bottom: 20px; */
}

.add-button:hover {
    background-color: #5a6268;
    transform: translateY(-2px);
}

.add-button:active {
    background-color: #5a6268;
    transform: translateY(0);
}

.submit-button {
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    padding: 14px 24px;
    font-size: 18px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    width: 100%;
}

.submit-button:hover {
    background-color: #0069d9;
    transform: translateY(-2px);
}

.submit-button:active {
    background-color: #005cbf;
    transform: translateY(0);
}

/* General Input and Select Styling */
.performance-plan-form input,
.performance-plan-form select {
    width: 100%;
}

/* Messages */
.message {
    margin-top: 20px;
    font-size: 16px;
    color: #0069d9;
    text-align: center;
    padding: 10px;
    border-radius: 6px;
    background-color: var(--background-color);
}

.button-row {
    display: flex;
    gap: 1rem;      /* space between buttons */
    /* or use margin on the buttons themselves instead of gap */
    /* e.g. .button-row button { margin-right: 1rem; } */
}  

/* Responsive Design */
@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }

    .remove-button {
        top: 10px;
        right: 10px;
    }

    .performance-plan-container {
        padding: 20px;
    }

    .fetch-data-section, .fetched-data-table, .performance-plan-form {
        padding: 15px;
    }

    .toggle-fetch-button {
        padding: 8px 14px;
        font-size: 14px;
    }

    .get-button,
    .clear-button,
    .add-button,
    .submit-button {
        font-size: 14px;
        padding: 10px 16px;
    }

    .performance-plan-form h2,
    .fetch-data-section h2,
    .fetched-data-table h2 {
        font-size: 20px;
    }

    .entry-group h3 {
        font-size: 16px;
    }
}

/* Hide spinner arrows in number inputs */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

/* Smooth transitions */
* {
    transition: background-color 0.3s ease, color 0.3s ease;
}
