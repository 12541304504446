/* TOReport.css */

/* Container Styling */
.to-report {
  padding: 20px;
  background-color: var(--report-background-color);
  border-radius: 8px;
  box-shadow: var(--report-box-shadow);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Controls Row */
.controls-row {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.controls-row label {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: var(--table-header-text); /* Adapts text color based on theme */
}

.controls-row input[type="date"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  background-color: var(--dropdown-bg);
  color: var(--table-header-text);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.controls-row input[type="date"]::placeholder {
  color: #999;
}

/* Fetch Button */
.fetch-button {
  padding: 8px 12px;
  background-color: var(--fetch-button-bg);
  color: var(--fetch-button-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fetch-button:hover {
  background-color: var(--fetch-button-hover-bg);
}

.fetch-button:focus {
  outline: 2px solid var(--fetch-button-hover-bg);
  outline-offset: 2px;
}

/* Export Section */
.export-section {
  position: relative;
  display: inline-block;
}

/* Download Button */
.download-button {
  padding: 8px 12px;
  background-color: var(--download-button-bg);
  color: var(--download-button-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: var(--download-button-hover-bg);
}

.download-button:focus {
  outline: 2px solid var(--download-button-hover-bg);
  outline-offset: 2px;
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--dropdown-bg);
  border: 1px solid var(--dropdown-border);
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: auto;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}

.dropdown-item {
  padding: 8px 12px;
  border: none;
  background-color: var(--dropdown-item-bg);
  color: var(--dropdown-item-text);
  text-align: left;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-item:hover {
  background-color: var(--dropdown-item-hover-bg);
  color: var(--dropdown-item-hover-text);
}

.dropdown-item:focus {
  background-color: var(--dropdown-item-focus-bg);
  color: var(--dropdown-item-focus-text);
  outline: none;
}

/* Report Table */
.report-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.report-table th,
.report-table td {
  border: 1px solid var(--table-border);
  padding: 8px;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.report-table th {
  background-color: var(--table-header-bg);
  color: var(--table-header-text);
}

.report-table tr:nth-child(even) {
  background-color: var(--table-row-even-bg);
}

.report-table tr:nth-child(odd) {
  background-color: var(--table-row-odd-bg);
}

.report-table tr:hover {
  background-color: var(--table-row-hover-bg);
}

/* Loading Indicator */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px; /* Adjust as needed */
  font-size: 18px;
  color: var(--table-header-text);
}

/* Responsive Design */
@media (max-width: 768px) {
  .controls-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .export-section {
    width: 100%;
  }

  .download-button {
    width: 100%;
    justify-content: center;
  }

  .dropdown-menu {
    left: 0;
    right: 0;
  }

  .dropdown-item {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .controls-row label {
    font-size: 14px;
  }

  .fetch-button,
  .download-button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .report-table th,
  .report-table td {
    padding: 6px;
    font-size: 12px;
  }

  .loading-indicator {
    font-size: 16px;
  }
}
