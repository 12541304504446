/* index.css */

/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* 1. Define CSS Variables for Light and Dark Themes */
button:focus, button:active {
  outline: none !important;
  box-shadow: none !important;
}

/* Light Theme (Default) */
:root {
  /* Backgrounds */
  --background-color: #ffffff;
  --header-background-color: #003366;
  --side-nav-background-color: #f5f5f5;
  --content-background-color: #ffffff;

  /* Text */
  --text-color: #000000;
  --neutral-text-color: #000000;
  --header-text-color: #ffffff;
  --side-nav-group-text-color: #000000;
  --side-nav-text-color: #000000;
  --dropdown-text-color: #000000;
  --button-text-color: #ffffff;

  /* Borders and Shadows */
  --box-shadow-color: rgba(0, 0, 0, 0.1);

  /* Hover and Selected States */
  --side-nav-hover-background-color: #e0e0e0;
  --side-nav-selected-background-color: #fff;

  /* Icons */
  --side-nav-icon-color: #003366;

  /* Buttons */
  --toggle-button-background-color: #007bff;
  --toggle-button-hover-background-color: #0056b3;

  /* Dropdowns */
  --dropdown-background-color: #ffffff;
  --dropdown-hover-background-color: #f1f1f1;

  /* Dark Mode Toggle */
  --dark-mode-toggle-background: #e0e0e0;
  --dark-mode-toggle-hover-background: #f1f1f1;

  /* Neutral Colors */
  --neutral-background-color: #ffffff;

  /* Links */
  --link-color: #61dafb;
  --link-hover-color: #21a1f1;

  /* Additional Variables */
  --card-background-color: var(--neutral-background-color);
  --highlight-color: #0056b3; /* For specific highlights */
  --modal-background-color: rgba(0, 0, 0, 0.5);
  
    /* New Variables for SwitchBoardPage */
    --table-border-color: #ddd;
    --table-header-background-color: #f2f2f2;
    --button-background-color: #007bff; /* Primary button color */
    --button-text-color: #ffffff;
    --button-hover-background-color: #0056b3;

      /* New Variables for SalesDashboard */
  --dashboard-background-color: #f9f9f9;
  --table-border-color: #ddd;
  --table-header-background-color: #f2f2f2;
  --button-background-color: #ffffff;
  --button-text-color: #333333;
  --button-hover-background-color: #0056b3;
  --sale-type-button-background-color: #f0f0f0;
  --sale-type-button-text-color: #333333;
  --sale-type-button-hover-background-color: #0056b3;
  --sale-type-button-hover-text-color: #ffffff;
  --chart-title-color: #333333;
  --tooltip-background-color: rgba(0, 51, 102, 0.9);
  --tooltip-text-color: #ffffff;
  --switch-slider-background-color: #ccc;
  --switch-slider-checked-background-color: #007bff;
  --sales-analytics-title-color: #333333;
  --month-button-background-color: #ffffff;
  --month-button-text-color: #000000;
  --month-button-hover-background-color: #0056b3;
  --month-button-hover-text-color: #ffffff;

    /* New Variables for LineChartD3 */
    --chart-background-color: #ffffff; /* Background color for chart wrapper */
    --chart-text-color: #333333; /* Text color for chart titles and axis labels */
    --axis-line-color: #333333; /* Color for axis lines and ticks */
    --tooltip-background-color: rgba(0, 51, 102, 0.9); /* Tooltip background */
    --tooltip-text-color: #ffffff; /* Tooltip text color */
    --tooltip-box-shadow-color: rgba(0, 0, 0, 0.1); /* Tooltip box shadow */
    --slider-background-color: #ccc; /* Toggle slider background */
    --slider-checked-background-color: #007bff; /* Toggle slider background when checked */
    --tooltip-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1); /* Tooltip box shadow */

    /* New Variables for CustomerLogForm */
  --form-background-color: #f9f9f9; /* Background color for form container */
  --form-text-color: #333333; /* Text color for form */
  --button-background-color: #007bff; /* Default button background */
  --button-text-color: #ffffff; /* Default button text color */
  --button-border-color: #007bff; /* Default button border color */
  --button-hover-background-color: #0056b3; /* Button background on hover */
  --button-hover-border-color: #0056b3; /* Button border on hover */
  --input-border-color: #ccc; /* Border color for inputs */
  --input-background-color: #ffffff; /* Background color for inputs */
  --checkbox-accent-color: #007bff; /* Accent color for checkboxes */

   /* New Variables for Search Input and Fetched Data */
   --search-input-background-color: #ffffff; /* Background color for search input */
   --search-input-text-color: #333333; /* Text color for search input */
   --fetched-data-background-color: #f0f0f0; /* Background color for fetched-data section */
   --fetched-data-text-color: #333333; /* Text color for fetched-data section */
   --fetched-data-border-color: #ccc; /* Border color for fetched-data section */

   /* New Variables for Loading Animation */
  --loading-background-color: #f0f0f0; /* Background color for loading screen */
  --loading-car-color: #000000; /* Color of the car */
  --loading-smoke-color: rgba(0, 0, 0, 0.2); /* Color of the smoke */
  --loading-text-color: #555555; /* Text color for loading message */

  /* New Variables for Sales Form */
  --sales-form-background-color: #f9f9f9; /* Background color for sales form */
  --sales-form-text-color: #333333; /* Text color for sales form */
  --section-header-color: #003366; /* Section header color */
  --divider-color: #ddd; /* Divider line color */
  --form-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for form container */

/* New Variables for Inventory Form */
--inventory-form-background-color: #f9f9f9; /* Background color for inventory form */
--inventory-form-text-color: #333333; /* Text color for inventory form */
--inventory-heading-color: #003366; /* Heading color */
--inventory-subheading-color: #c0c0c0; /* Subheading color */
--inventory-divider-color: #ddd; /* Divider color */
--inventory-form-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow */

/* New Variables for Inventory Secondary Buttons */
--button-secondary-background-color: #c0c0c0; /* Secondary button background */
--button-secondary-border-color: #c0c0c0; /* Secondary button border */
--button-secondary-hover-background-color: #007bff; /* Secondary button hover background */
--button-secondary-border-hover-color: #007bff; /* Secondary button hover border */

/* New Variables for Inventory Data */
--inventory-data-background-color: #f9f9f9; /* Background color for inventory data container */
--inventory-data-text-color: #333333; /* Text color for inventory data */
--inventory-data-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for inventory data container */
--inventory-data-heading-color: #003366; /* Heading color */
--inventory-data-subheading-color: #c0c0c0; /* Subheading color */
--header-divider-color: #c0c0c0; /* Divider color for headers */

/* Status Indicators */
--status-sold-color: red; /* Color for sold status */
--status-available-color: green; /* Color for available status */

/* Download Icon */
--download-icon-color: white; /* Default color for download icon */
--download-icon-hover-color: #0056b3; /* Hover color for download icon */

--label-color: #333333;

/* New Variables for VariableLeaders */
--leaderboard-background-color: #f9f9f9; /* Background color for leaderboard container */
--leaderboard-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for leaderboard container */

--leaderboard-heading-color: #003366; /* Color for h2 headings */

--leaderboard-table-border-color: #ddd; /* Border color for table cells */
--leaderboard-table-header-background-color: #003366; /* Background color for table headers */
--leaderboard-table-header-text-color: #ffffff; /* Text color for table headers */
--leaderboard-table-row-even-background-color: #f2f2f2; /* Background color for even table rows */
--leaderboard-table-row-odd-background-color: #ffffff; /* Background color for odd table rows */
--leaderboard-table-row-hover-background-color: #ddd; /* Background color on table row hover */

--leaderboard-table-cell-text-color: #333333; /* Text color for table cells */

--leaderboard-even-row-background-color: #f9f9f9; /* Background color for .even-row */
--leaderboard-odd-row-background-color: #ffffff; /* Background color for .odd-row */

/* New Variables for SalesReport */
--sales-report-background-color: #f9f9f9; /* Background color for sales report container */
--sales-report-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for sales report container */

--sales-report-heading-color: #003366; /* Color for h2 headings */

--sales-report-section-background-color: #ffffff; /* Background color for totals sections */
--sales-report-section-border-color: #ccc; /* Border color for totals sections */

--sales-report-section-heading-color: #003366; /* Color for h3 headings in totals sections */
--sales-report-section-heading-border-color: #ccc; /* Border-bottom color for h3 headings */

--sales-report-section-text-color: #333333; /* Text color for paragraphs in totals sections */

--sales-report-table-border-color: #ddd; /* Border color for table cells */
--sales-report-table-header-background-color: #003366; /* Background color for table headers */
--sales-report-table-header-text-color: #ffffff; /* Text color for table headers */
--sales-report-table-row-even-background-color: #f2f2f2; /* Background color for even table rows */
--sales-report-table-row-odd-background-color: #ffffff; /* Background color for odd table rows */
--sales-report-table-row-hover-background-color: #ddd; /* Background color on table row hover */

--sales-report-table-cell-text-color: #333333; /* Text color for table cells */

/* New Variables for TOReport */
--report-background-color: #ffffff;
  --report-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  --fetch-button-bg: #007bff;
  --fetch-button-text: #ffffff;
  --fetch-button-hover-bg: #0056b3;

  --download-button-bg: #007bff;
  --download-button-text: #ffffff;
  --download-button-hover-bg: #0056b3;

  --dropdown-bg: #ffffff;
  --dropdown-border: #ddd;
  --dropdown-item-bg: transparent;
  --dropdown-item-text: #007bff;
  --dropdown-item-hover-bg: #f0f0f0;
  --dropdown-item-hover-text: #0056b3;

  --table-border: #ddd;
  --table-header-bg: #f2f2f2;
  --table-header-text: #000000;
  --table-row-even-bg: #f9f9f9;
  --table-row-odd-bg: #ffffff;
  --table-row-hover-bg: #e0e0e0;

  --dropdown-item-focus-bg: #e0e0e0;
  --dropdown-item-focus-text: #0056b3;

  /* HomePage */
  --calendar-header-background-color: #f0f0f0;
  --calendar-header-text-color: #333333;
  --button-background-color: #007bff;
  --button-text-color: #ffffff;
  --button-hover-background-color: #0056b3;
  --card-background-color: #ffffff;
  --neutral-text-color: #333333;
  --current-day-background-color: rgba(0, 123, 255, 0.2);
  --current-day-text-color: #000000;
  --side-nav-hover-background-color: #f0f0f0;
  --box-shadow-color: rgba(0, 0, 0, 0.1);
  --scrollbar-thumb-color: #8888881a;
}


/* Dark Theme */
.dark-mode {
  /* Backgrounds */
  --background-color: #181a1b;
  --header-background-color: #013568;
  --side-nav-background-color: #1e2021;
  --content-background-color: #181a1b;

  /* Text */
  --text-color: #e8e6e3;
  --neutral-text-color: #e8e6e3;
  --header-text-color: #e8e6e3;
  --side-nav-group-text-color: #e8e6e3;
  --side-nav-text-color: #e8e6e3;
  --dropdown-text-color: #e8e6e3;
  --button-text-color: #e8e6e3;

  /* Borders and Shadows */
  --box-shadow-color: rgba(0, 0, 0, 0.1);

  /* Hover and Selected States */
  --side-nav-hover-background-color: #2a2d2f;
  --side-nav-selected-background-color: #333739;

  /* Icons */
  --side-nav-icon-color: #8fcdff;

  /* Buttons */
  --toggle-button-background-color: #0062cc;
  --toggle-button-hover-background-color: #00458f;

  /* Dropdowns */
  --dropdown-background-color: #181a1b;
  --dropdown-hover-background-color: #202324;

  /* Dark Mode Toggle */
  --dark-mode-toggle-background: #202325;
  --dark-mode-toggle-hover-background: #2a2d2f;

  /* Neutral Colors for Dark Mode */
  --neutral-background-color: #181a1b;

  /* Links */
  --link-color: #61dafb;
  --link-hover-color: #21a1f1;

  /* Additional Variables for Dark Mode */
  --card-background-color: var(--neutral-background-color);
  --highlight-color: #61b7ff; /* Adjust as needed for dark mode */
  --modal-background-color: rgba(0, 0, 0, 0.5);

  /* Adjusted Variables for SwitchBoardPage */
  --table-border-color: #444444;
  --table-header-background-color: #333333;
  --button-background-color: #1a73e8;
  --button-text-color: #ffffff;
  --button-hover-background-color: #135ba1;

  /* Adjusted Variables for SalesDashboard */
  --dashboard-background-color: #1e1e1e;
  --table-border-color: #444444;
  --table-header-background-color: #333333;
  /* --button-background-color: #2c2c2c; */
  --button-text-color: #ffffff;
  --button-hover-background-color: #007bff;
  --sale-type-button-background-color: #2c2c2c;
  --sale-type-button-text-color: #ffffff;
  --sale-type-button-hover-background-color: #007bff;
  --sale-type-button-hover-text-color: #ffffff;
  --chart-title-color: #ffffff;
  --tooltip-background-color: rgba(0, 0, 0, 0.9);
  --tooltip-text-color: #ffffff;
  --switch-slider-background-color: #555555;
  --switch-slider-checked-background-color: #007bff;
  --sales-analytics-title-color: #ffffff;
  --month-button-background-color: #2c2c2c;
  --month-button-text-color: #ffffff;
  --month-button-hover-background-color: #007bff;
  --month-button-hover-text-color: #ffffff;

  /* Adjusted Variables for LineChartD3 */
  --chart-background-color: #1e1e1e; /* Dark background for chart wrapper */
  --chart-text-color: #ffffff; /* White text for chart titles and axis labels */
  --axis-line-color: #ffffff; /* White color for axis lines and ticks */
  --tooltip-background-color: rgba(0, 0, 0, 0.9); /* Dark tooltip background */
  --tooltip-text-color: #ffffff; /* Tooltip text remains white */
  --tooltip-box-shadow-color: rgba(255, 255, 255, 0.1); /* Tooltip box shadow in dark mode */
  --slider-background-color: #555555; /* Darker slider background */
  --slider-checked-background-color: #007bff; /* Slider checked remains blue */
  --tooltip-box-shadow: 0px 0px 6px rgba(255, 255, 255, 0.1); /* Tooltip box shadow in dark mode */

   /* Adjusted Variables for CustomerLogForm */
   --form-background-color: #1e2021; /* Dark background for form container */
   --form-text-color: #ffffff; /* White text color for form */
   --button-background-color: #007bff; /* Button background remains blue */
   --button-text-color: #ffffff; /* Button text remains white */
   --button-border-color: #007bff; /* Button border remains blue */
   --button-hover-background-color: #0056b3; /* Button hover background remains darker blue */
   --button-hover-border-color: #0056b3; /* Button hover border remains darker blue */
   --input-border-color: #444444; /* Darker border color for inputs */
   --input-background-color: #2c2c2c; /* Dark background for inputs */
   --checkbox-accent-color: #007bff; /* Checkbox accent color remains blue */

   /* Adjusted Variables for Search Input and Fetched Data */
  --search-input-background-color: #2c2c2c; /* Dark background for search input */
  --search-input-text-color: #ffffff; /* White text color for search input */
  --fetched-data-background-color: #2c2c2c; /* Dark background for fetched-data section */
  --fetched-data-text-color: #ffffff; /* White text color for fetched-data section */
  --fetched-data-border-color: #555555; /* Darker border color for fetched-data section */

  /* Adjusted Variables for Loading Animation */
  --loading-background-color: #1e1e1e; /* Dark background for loading screen */
  --loading-car-color: #ffffff; /* White color of the car */
  --loading-smoke-color: rgba(255, 255, 255, 0.2); /* Light smoke color */
  --loading-text-color: #ffffff; /* White text color for loading message */

  /* Adjusted Variables for Sales Form */
  --sales-form-background-color: #1e1e1e; /* Dark background for sales form */
  --sales-form-text-color: #ffffff; /* White text color for sales form */
  --section-header-color: #6699ff; /* Light blue or another suitable color for dark mode */
  --divider-color: #555555; /* Darker divider line color */
  --form-box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Light box shadow for dark mode */

  /* Adjusted Variables for Inventory Form */
  --inventory-form-background-color: #1e1e1e; /* Dark background for inventory form */
  --inventory-form-text-color: #ffffff; /* White text color for inventory form */
  --inventory-heading-color: #6699ff; /* Light blue or another suitable color for dark mode */
  --inventory-subheading-color: #cccccc; /* Subheading color for dark mode */
  --inventory-divider-color: #555555; /* Divider color for dark mode */
  --inventory-form-box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1); /* Box shadow for dark mode */

  /* Adjusted Variables for Inventory Secondary Buttons */
  --button-secondary-background-color: #555555; /* Secondary button background for dark mode */
  --button-secondary-border-color: #555555; /* Secondary button border for dark mode */
  --button-secondary-hover-background-color: #007bff; /* Secondary button hover background */
  --button-secondary-border-hover-color: #007bff; /* Secondary button hover border */

  /* Adjusted Variables for Inventory Data */
  --inventory-data-background-color: #1e1e1e; /* Dark background for inventory data container */
  --inventory-data-text-color: #ffffff; /* White text color for inventory data */
  --inventory-data-box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Box shadow for inventory data container */
  --inventory-data-heading-color: #6699ff; /* Light blue or another suitable color for dark mode */
  --inventory-data-subheading-color: #cccccc; /* Subheading color for dark mode */
  --header-divider-color: #c0c0c0; /* Divider color for headers */

  /* Status Indicators */
  --status-sold-color: #ff4d4d; /* Lighter red for dark mode */
  --status-available-color: #4dff88; /* Lighter green for dark mode */

  /* Download Icon */
  --download-icon-color: #ffffff; /* Default color for download icon */
  --download-icon-hover-color: #0056b3; /* Hover color for download icon */

  --label-color: #ffffff;

  /* Adjusted Variables for VariableLeaders */
  --leaderboard-background-color: #1e1e1e; /* Dark background for leaderboard container */
  --leaderboard-box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Box shadow for leaderboard container in dark mode */
  
  --leaderboard-heading-color: #6699ff; /* Light blue color for h2 headings in dark mode */
  
  --leaderboard-table-border-color: #555555; /* Darker border color for table cells in dark mode */
  --leaderboard-table-header-background-color: #6699ff; /* Light blue background for table headers in dark mode */
  --leaderboard-table-header-text-color: #ffffff; /* Text color remains white for contrast */
  --leaderboard-table-row-even-background-color: #2c2c2c; /* Dark background for even table rows in dark mode */
  --leaderboard-table-row-odd-background-color: #1e1e1e; /* Dark background for odd table rows in dark mode */
  --leaderboard-table-row-hover-background-color: #555555; /* Darker background on table row hover in dark mode */
  
  --leaderboard-table-cell-text-color: #ffffff; /* White text color for table cells in dark mode */
  
  --leaderboard-even-row-background-color: #2c2c2c; /* Dark background for .even-row in dark mode */
  --leaderboard-odd-row-background-color: #1e1e1e; /* Dark background for .odd-row in dark mode */

  /* Adjusted Variables for SalesReport */
  --sales-report-background-color: #1e1e1e; /* Dark background for sales report container */
  --sales-report-box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1); /* Box shadow for sales report container in dark mode */
  
  --sales-report-heading-color: #6699ff; /* Light blue color for h2 headings in dark mode */
  
  --sales-report-section-background-color: #2c2c2c; /* Dark background for totals sections in dark mode */
  --sales-report-section-border-color: #555555; /* Darker border color for totals sections in dark mode */
  
  --sales-report-section-heading-color: #6699ff; /* Light blue color for h3 headings in totals sections in dark mode */
  --sales-report-section-heading-border-color: #555555; /* Darker border-bottom color for h3 headings in dark mode */
  
  --sales-report-section-text-color: #ffffff; /* White text color for paragraphs in totals sections in dark mode */
  
  --sales-report-table-border-color: #555555; /* Darker border color for table cells in dark mode */
  --sales-report-table-header-background-color: #6699ff; /* Light blue background for table headers in dark mode */
  --sales-report-table-header-text-color: #ffffff; /* Text color remains white for contrast */
  --sales-report-table-row-even-background-color: #2c2c2c; /* Dark background for even table rows in dark mode */
  --sales-report-table-row-odd-background-color: #1e1e1e; /* Dark background for odd table rows in dark mode */
  --sales-report-table-row-hover-background-color: #555555; /* Darker background on table row hover in dark mode */
  
  --sales-report-table-cell-text-color: #ffffff; /* White text color for table cells in dark mode */

  /* Adjusted Variables for TOReport */
  --report-background-color: #1e1e1e;
  --report-box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);

  --fetch-button-bg: #6699ff;
  --fetch-button-text: #1e1e1e;
  --fetch-button-hover-bg: #003366;

  --download-button-bg: #6699ff;
  --download-button-text: #1e1e1e;
  --download-button-hover-bg: #003366;

  --dropdown-bg: #2c2c2c;
  --dropdown-border: #555555;
  --dropdown-item-bg: #2c2c2c;
  --dropdown-item-text: #6699ff;
  --dropdown-item-hover-bg: #555555;
  --dropdown-item-hover-text: #ffffff;

  --table-border: #555555;
  --table-header-bg: #6699ff;
  --table-header-text: #ffffff;
  --table-row-even-bg: #2c2c2c;
  --table-row-odd-bg: #1e1e1e;
  --table-row-hover-bg: #555555;

  --dropdown-item-focus-bg: #555555;
  --dropdown-item-focus-text: #ffffff;

  --calendar-header-background-color: #333333; /* Dark mode background */
  --calendar-header-text-color: #f0f0f0;
  --current-day-background-color: rgba(0, 123, 255, 0.5);
  --current-day-text-color: #ffffff;

  /* HomePage */
  --button-background-color: #444444;
  --button-text-color: #ffffff;
  --button-hover-background-color: #333333;
  --card-background-color: #2b2b2b;
  --neutral-text-color: #f0f0f0;
  --current-day-background-color: rgba(0, 123, 255, 0.5);
  --current-day-text-color: #ffffff;
  --side-nav-hover-background-color: #3a3a3a;
  --box-shadow-color: rgba(0, 0, 0, 0.5);
  --scrollbar-thumb-color: #555;
}

/* 2. Global Styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* Apply global background and text colors */
  background-color: var(--background-color);
  color: var(--text-color);
  
  /* Smooth transitions for theme changes */
  transition: background-color 0.3s ease, color 0.3s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}