/* VariableLeaders.css */

.filter-month {
  background-color: var(--leaderboard-background-color); /* Use CSS Variable */
  border: 1px solid var(--leaderboard-table-border-color); /* Use CSS Variable */
  border-radius: 10px;
  padding: 5px;
}

.filter-sale-type {
  background-color: var(--leaderboard-background-color); /* Use CSS Variable */
  border: 1px solid var(--leaderboard-table-border-color); /* Use CSS Variable */
  border-radius: 10px;
  padding: 5px;
}

.leaderboard-container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: var(--leaderboard-background-color); /* Use CSS Variable */
  border-radius: 8px;
  box-shadow: var(--leaderboard-box-shadow); /* Use CSS Variable */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.leaderboard-container h2 {
  text-align: center;
  color: var(--leaderboard-heading-color); /* Use CSS Variable */
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.leaderboard-container .leaderboard-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.leaderboard-container .leaderboard-table th,
.leaderboard-container .leaderboard-table td {
  padding: 12px 15px;
  text-align: center;
  border: 1px solid var(--leaderboard-table-border-color); /* Use CSS Variable */
  transition: border-color 0.3s ease;
}

.leaderboard-container .leaderboard-table th {
  background-color: var(--leaderboard-table-header-background-color); /* Use CSS Variable */
  color: var(--leaderboard-table-header-text-color); /* Use CSS Variable */
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.leaderboard-container .leaderboard-table tr:nth-child(even) {
  background-color: var(--leaderboard-table-row-even-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.leaderboard-container .leaderboard-table tr:nth-child(odd) {
  background-color: var(--leaderboard-table-row-odd-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.leaderboard-container .leaderboard-table tr:hover {
  background-color: var(--leaderboard-table-row-hover-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.leaderboard-container .leaderboard-table td {
  color: var(--leaderboard-table-cell-text-color); /* Use CSS Variable */
  transition: color 0.3s ease;
}

.leaderboard-container .even-row {
  background-color: var(--leaderboard-even-row-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.leaderboard-container .odd-row {
  background-color: var(--leaderboard-odd-row-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .leaderboard-container {
      padding: 10px;
      transition: padding 0.3s ease;
  }

  .leaderboard-container .leaderboard-table th,
  .leaderboard-container .leaderboard-table td {
      padding: 8px 10px;
      transition: padding 0.3s ease;
  }

  .leaderboard-container h2 {
      font-size: 1.5rem;
      transition: font-size 0.3s ease;
  }
}
