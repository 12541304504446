/* HomePage.css */

/* General Styles */
.home-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px;
  font-family: Arial, sans-serif;
  color: var(--neutral-text-color);
  background-color: var(--neutral-background-color);
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--box-shadow-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.home-page h2 {
  text-align: center;
  color: var(--text-color);
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  transition: color 0.3s ease;
}

/* KPI Styles */
.kpis {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.kpi-item {
  display: flex;
  align-items: center;
  background: var(--card-background-color);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--box-shadow-color);
  width: 30%; /* Adjust based on layout */
}

.kpi-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--highlight-color);
  margin-right: 15px;
}

.kpi-icon {
  font-size: 28px;
  color: white;
}

.kpi-text h3 {
  margin: 0;
  font-size: 1rem;
  color: var(--text-color);
  font-weight: bold;
}

.kpi-text p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--highlight-color);
}

/* Main Content Styles */
.main-content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

/* Adjusted Containers */
.calendar-container {
  flex: 1 1 35%;
  min-width: 300px;
  max-width: 500px;
  margin-right: 10px;
}

.fc {
  max-width: 100%;
  margin: 0 auto;
}

.fc .fc-toolbar {
  margin-bottom: 10px;
}

.fc .fc-toolbar-title {
  font-size: 16px;
  color: var(--neutral-text-color);
  display: flex;
  align-items: center;
}

.fc-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.fc-button:hover {
  background-color: var(--button-hover-background-color);
}

.fc-button:disabled {
  background-color: #ccc;
  cursor: default;
}

.fc .fc-col-header {
  background-color: var(--background-color);
  color: var(--calendar-header-text-color);
}

.fc .fc-col-header-cell-cushion {
  font-weight: bold;
}

/* Remove background colors from parent elements */
.fc .fc-daygrid-day,
.fc .fc-day-other,
.fc .fc-day-today {
  background-color: transparent !important;
}

/* Apply background colors to the day frame */
.fc .fc-daygrid-day-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 0;
  border-radius: 8px;
  margin: 2px;
  height: 60px;
  overflow: hidden;
  background-color: var(--card-background-color);
}

.fc .fc-daygrid-day-frame:hover {
  background-color: var(--side-nav-hover-background-color);
}

.fc .fc-day-today .fc-daygrid-day-frame {
  background-color: var(--current-day-background-color) !important;
  color: var(--current-day-text-color) !important;
  overflow: hidden;
}

.fc .fc-day-number {
  text-align: center;
  font-weight: bold;
  margin-bottom: 4px;
}

.day-cell-content,
.dots-container,
.event-dot {
  pointer-events: none;
}

.day-cell-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dots-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.event-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 2px;
}

/* Remove calendar gridlines */
.fc-theme-standard td,
.fc-theme-standard th {
  border: none;
}

.fc .fc-scrollgrid {
  border: none;
}

.fc .fc-daygrid-day-number {
  padding: 2px 4px;
}

.fc .fc-daygrid-day-top {
  margin-bottom: 2px;
}

.fc .fc-daygrid-day-events {
  flex-grow: 1;
}

.fc-event {
  display: none;
}

/* Calendar Legend */
.calendar-legend {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}

.calendar-legend span {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  color: var(--neutral-text-color);
}

.legend-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}

.legend-dot.urgent {
  background-color: red;
}

.legend-dot.high {
  background-color: orange;
}

.legend-dot.low {
  background-color: green;
}

/* Tasks Container */
.tasks-container {
  flex: 1 1 65%;
  min-width: 400px;
  max-width: 650px;
  max-height: 475px;
  margin-left: 10px;
  background-color: var(--card-background-color);
  padding: 0 20px 20px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px var(--box-shadow-color);
  overflow-y: auto;
  transition: background-color 0.3s ease, color 0.3s ease;
  scrollbar-color: var(--side-nav-hover-background-color);
}

/* Tabs and Buttons Container */
.tabs-and-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Tabs Styling */
.tabs {
  display: flex;
}

.tabs button {
  margin-bottom: -5px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  background-color: var(--card-background-color);
  border: none;
  border-bottom: 3px solid var(--side-nav-hover-background-color);
  font-size: 16px;
  color: var(--neutral-text-color);
  transition: background-color 0.3s ease, border-bottom 0.3s ease;
  outline: none;
}

.tabs button:hover {
  background-color: var(--side-nav-hover-background-color);
  transform: translateY(15px);
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.tabs button.active {
  border-bottom: 3px solid var(--button-background-color);
  font-weight: bold;
  background-color: var(--side-nav-hover-background-color);
  margin-bottom: -25px;
  outline: none;
}

/* Add Task Button Alignment */
.add-task-button {
  display: flex;
  align-items: center;
}

.add-task-button button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
}

.add-task-button button:hover {
  background-color: var(--button-hover-background-color);
}

/* Table Header Sort Arrows */
.tasks-container thead th {
  position: relative;
  cursor: pointer;
  pointer-events: auto;
}

.tasks-container thead th.sort-asc::after,
.tasks-container thead th.sort-desc::after {
  position: absolute;
  right: 10px;
  top: 50%;
  border: solid transparent;
  pointer-events: none;
  border-width: 6px;
  border-radius: 2px;
  transform: translateY(-50%);
}

.tasks-container thead th.sort-asc::after {
  content: ' ↑';
}

tasks-container thead th.sort-desc::after {
  content: ' ↓';
}

.tasks-container thead th span.sort-arrow {
  display: none;
}

/* Table Styling */
.tasks-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.tasks-container thead th {
  background-color: var(--table-header-background-color, var(--neutral-background-color));
  padding: 10px;
  text-align: left;
  color: var(--text-color);
  border-bottom: 2px solid var(--box-shadow-color);
  cursor: pointer;
  user-select: none;
  transition: color 0.3s ease, background-color 0.3s ease;
  white-space: nowrap;
}

.tasks-container thead th:hover {
  color: var(--link-hover-color);
}

.tasks-container tbody td {
  padding: 10px;
  border-bottom: 1px solid var(--box-shadow-color);
  color: var(--neutral-text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tasks-container tr:hover {
  background-color: var(--side-nav-hover-background-color);
}

.tasks-container tr {
  background-color: transparent;
}

/* Status Badge Styles */
.status-badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 12px;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.status-active {
  background-color: #ccc;
}

.status-pending {
  background-color: #ffc107;
  color: #000;
}

.status-cancelled {
  background-color: #dc3545;
}

.status-completed {
  background-color: #28a745;
}

.status-overdue {
  background-color: #ff5722;
}

/* Priority Styles */
.priority-urgent {
  color: red;
  font-weight: bold;
}

.priority-high {
  color: orange;
  font-weight: bold;
}

.priority-low {
  color: green;
  font-weight: bold;
}

/* Tooltip Styling */
.react-tooltip {
  max-width: 200px;
  background-color: var(--tooltip-background-color, rgba(0, 0, 0, 0.9));
  color: var(--tooltip-text-color, #ffffff);
  border-radius: 4px;
  padding: 10px;
  box-shadow: var(--tooltip-box-shadow-color, 0px 0px 6px rgba(0, 0, 0, 0.1));
}

.react-tooltip div {
  margin-top: 5px;
  line-height: 1.5;
}

/* Modal Styles */
.modal {
  background-color: var(--card-background-color);
  color: var(--neutral-text-color);
  padding: 20px;
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 5px 15px var(--box-shadow-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--modal-background-color);
  z-index: 1000;
}

.modal h2 {
  margin-top: 0;
  color: var(--text-color);
}

.modal label {
  display: block;
  margin-bottom: 15px;
  color: var(--neutral-text-color);
}

.modal input[type="text"],
.modal input[type="date"],
.modal textarea,
.modal select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid var(--input-border-color, #ccc);
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--input-background-color, #ffffff);
  color: var(--neutral-text-color);
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.modal textarea {
  resize: vertical;
  min-height: 80px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.modal-buttons button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.modal-buttons button[type="submit"] {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
}

.modal-buttons button[type="button"] {
  background-color: var(--button-hover-background-color);
  color: var(--button-text-color);
}

.modal-buttons button:hover {
  opacity: 0.9;
}

.modal select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 12px;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6"><path fill="%23999" d="M0 0l6 6 6-6z"/></svg>');
}

.dark-mode .modal select {
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="6"><path fill="%23ccc" d="M0 0l6 6 6-6z"/></svg>');
}

.modal select:focus {
  outline: none;
  border-color: var(--button-background-color);
}

.modal input[type="text"]:focus,
.modal input[type="date"]:focus,
.modal textarea:focus {
  outline: none;
  border-color: var(--button-background-color);
}

/* Scrollbar Styles for WebKit Browsers (Chrome, Safari) */
.tasks-container::-webkit-scrollbar {
  width: 10px;
}

.tasks-container::-webkit-scrollbar-track {
  background: var(--card-background-color);
}

.tasks-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
  border-radius: 5px;
  border: 2px solid var(--card-background-color);
}

/* Scrollbar Styles for Firefox */
.tasks-container {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb-color) var(--card-background-color);
}

/* Calendar Buttons Styles */
.fc .fc-today-button,
.fc .fc-prev-button,
.fc .fc-next-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: none;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.fc .fc-today-button:hover,
.fc .fc-prev-button:hover,
.fc .fc-next-button:hover {
  background-color: var(--button-hover-background-color);
}

.fc .fc-prev-button .fc-icon,
.fc .fc-next-button .fc-icon,
.fc .fc-today-button .fc-icon {
  color: var(--button-text-color);
}

.fc .fc-prev-button:hover .fc-icon,
.fc .fc-next-button:hover .fc-icon,
.fc .fc-today-button:hover .fc-icon {
  color: var(--button-text-color);
}

.fc .fc-button .fc-icon {
  font-size: 16px;
}

/* Aging Inventory Section */
.aging-inventory {
  margin: 10px 10px;
}

.aging-inventory-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aging-inventory h2 {
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  color: var(--neutral-text-color);
  margin-bottom: 15px;
  border-bottom: 2px solid var(--highlight-color);
  display: inline-block;
  padding-bottom: 5px;
}

/* Filter Section Title */
.aging-inventory h2 {
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  color: var(--neutral-text-color);
  margin-bottom: 15px;
  border-bottom: 2px solid var(--highlight-color);
  display: inline-block;
  padding-bottom: 5px;
}

/* Filter Buttons Container */
.filter-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Individual Buttons */
.filter-buttons button {
  padding: 10px 30px;
  border: none;
  cursor: pointer;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  font-size: 16px;
  border-radius: 25px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  position: relative;
}

.filter-buttons button.active {
  background-color: var(--button-hover-background-color);
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.filter-buttons button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Finance Contracts Section */
.finance-contracts {
  margin-top: 40px;
  padding: 20px;
  background: var(--card-background-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--box-shadow-color);
}

.finance-contracts h2 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: var(--text-color);
  text-align: center;
}

.finance-contracts-table {
  width: 100%;
  border-collapse: collapse;
}

.finance-contracts-table th {
  background-color: var(--table-header-background-color, var(--neutral-background-color));
  text-align: left;
  padding: 10px;
  font-size: 1rem;
  font-weight: bold;
}

.finance-contracts-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.finance-contracts-table tr:last-child td {
  border-bottom: none;
}

.finance-contracts-table tr {
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.finance-contracts-table tr:hover {
  background-color: var(--table-header-background-color);
  transform: scale(1.02);
  cursor: pointer;
}

/* Term Doughnut Styles */
.term-doughnut-container {
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;
}

.term-doughnut-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.8rem;
  font-weight: bold;
}

.remaining-days {
  display: inline-block;
  font-size: 0.8rem;
  color: #555;
  margin-left: 10px;
  vertical-align: middle;
}

.delta {
  margin-left: 8px;
  font-size: 0.9rem;
}

.increase {
  color: green;
}

.decrease {
  color: red;
}

/* Responsive Design */
@media (max-width: 768px) {
  .home-page {
    max-width: 100%;
    padding: 10px;
  }
  
  .kpis {
    flex-direction: column;
    align-items: center;
    width: 300px;
    place-self: center;
  }
  
  .kpi-item {
    width: 90%;
    margin-bottom: 10px;
  }
  
  .main-content {
    flex-direction: column;
  }
  
  .calendar-container,
  .tasks-container {
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .kpi-text h3 {
    font-size: 0.9rem;
  }
  
  .kpi-text p {
    font-size: 1.2rem;
  }
  
  .home-page h2 {
    font-size: 20px;
  }
  
  .tasks-container thead th,
  .tasks-container tbody td {
    font-size: 12px;
    padding: 8px;
  }
  
  .tabs button {
    font-size: 12px;
    padding: 6px;
  }
  
  .add-task-button button {
    font-size: 12px;
    padding: 6px 12px;
  }
  
  .modal {
    width: 90%;
  }
  
  /* Aging Inventory adjustments on mobile */
  .aging-inventory {
    margin: 10px 0;
  }
  
  .aging-inventory h2 {
    font-size: 20px;
    padding-bottom: 3px;
  }
  
  /* Finance Contracts adjustments on mobile */
  .finance-contracts {
    margin-top: 20px;
    padding: 15px;
  }
  
  .finance-contracts h2 {
    font-size: 1.3rem;
  }
  
  .finance-contracts-table th,
  .finance-contracts-table td {
    padding: 8px;
    font-size: 12px;
  }
}

/* Aging Inventory Age Bar Styles */
.age-bar-container {
  flex: 2;
  position: relative;
  margin-right: 10px;
  height: 16px;
}

.age-bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 8px;
  background-color: #ccc;
  border-radius: 4px;
  display: flex;
  z-index: 1;
}

.age-bar .segment {
  flex: 1;
  height: 100%;
}

.segment-30 {
  background-color: #adc7d1; /* Green for 0-30 days */
}

.segment-60 {
  background-color: #74b7cf; /* Yellow for 31-60 days */
}

.segment-90 {
  background-color: #38a2c9; /* Red for 61-90 days */
}

.segment-180 {
  background-color: #0088b9; /* Darker Red for 91-180 days */
}

.car-icon {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 35px;
  color: var(--highlight-color, #0056b3);
  z-index: 10;
}

.car-age {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
  margin-left: 10px;
}
