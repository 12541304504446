body {
  cursor: default;
}

.account-details-container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
  text-align: left;
}

h2 {
  font-size: 24px;
  text-align: center;
}

h2, h3 {
  color: #003366;
}

p {
  margin: 10px 0;
}

.support-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #e8f4f8;
  border-radius: 8px;
  text-align: center;
}

.support-form {
  margin-top: 15px;
}

.support-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.support-form textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.support-form button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.support-form button:hover {
  background-color: #0056b3;
}

p.success-message {
  color: green;
  margin-top: 10px;
}
