/* src/components/MainLayout.css */

/* Remove existing :root and .dark-mode definitions if present */

/* Header Styles */
.header {
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  height: 70px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.header-left {
  display: flex;
  align-items: center;
  flex: 1; /* Take up space on the left */
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  text-align: center;
  position: absolute; /* Position it absolutely */
  left: 50%; /* Start positioning from the center */
  transform: translateX(-50%); /* Move it to the true center */
}

.header-right {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1; /* Take up space on the right */
  justify-content: flex-end;
}

/* Logo Styles */
.logo-long {
  height: 50px;
  margin-right: 30px;
  display: block; /* Ensure the long logo is visible by default */
}

.logo-tall {
  height: 40px;
  margin-right: 10px;
  margin-left: -10px;
  display: none; /* Hide the tall logo by default */
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .logo-long {
    display: none; /* Hide the long logo on mobile */
  }

  .logo-tall {
    display: block; /* Show the tall logo on mobile */
    height: 40px; /* Adjust the height as necessary for the tall logo */
  }
}

/* Account Dropdown Styles */
.account-dropdown {
  position: relative;
  display: inline-block;
}

.account-link {
  color: var(--button-text-color);
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  flex-direction: column; /* Stack text vertically */
  align-items: center; /* Center the text horizontally */
  justify-content: center;
}

.account-name {
  font-size: 14px;
  margin-bottom: 2px; /* Add some space between the name and "Account" */
}

.account-text {
  font-size: 14px; /* Slightly smaller font for "Account" text */
  color: var(--button-text-color); /* Optional: Different color for the "Account" text */
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: var(--dropdown-background-color);
  width: 180px;
  box-shadow: 0px 8px 8px var(--box-shadow-color);
  z-index: 1;
  border-radius: 4px;
  overflow: hidden;
  flex-direction: column;
  align-items: stretch; /* Ensure children take full width */
}

.account-dropdown:hover .dropdown-content {
  display: flex; /* Show the dropdown content */
}

.dropdown-content a,
.dropdown-content button {
  text-align: center; /* Center text */
  padding: 8px 8px;
  box-sizing: border-box;
  color: var(--dropdown-text-color);
  background-color: var(--dropdown-background-color);
}

.dropdown-content a {
  text-decoration: none;
  width: 100%; /* Ensure full width */
}

.dropdown-content a:hover {
  background-color: var(--dropdown-hover-background-color);
}

.dropdown-content button {
  background-color: var(--toggle-button-background-color);
  color: var(--button-text-color);
  border: none;
  cursor: pointer;
  width: 100%; /* Ensure full width */
  font-size: 16px;
}

.dropdown-content button:hover {
  background-color: var(--toggle-button-hover-background-color);
}

.dropdown-content button:focus {
  outline: none;
}

/* Optional: Adjust for smaller screens */
@media (max-width: 600px) {
  .dropdown-content {
    width: 150px;
  }

  .dropdown-content a,
  .dropdown-content button {
    padding: 8px 8px;
    font-size: 16px;
  }
}

/* Main Layout Container */
.main-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color); /* Ensures full-page background */
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Layout Content Styles */
.layout-content {
  display: flex;
  flex: 1;
  padding-top: 70px; /* Matches header height */
  background-color: var(--background-color); /* Consistent background */
  transition: background-color 0.3s ease;
}

/* Side Navigation Styles */
.side-nav {
  position: fixed;
  top: 70px;
  left: 0;
  z-index: 100;
  width: 150px;
  height: calc(100% - 70px); /* Full height minus header */
  background-color: var(--side-nav-background-color);
  padding-top: 20px;
  overflow: visible;
  /* overflow-y: auto; */
  box-shadow: 2px 0 5px var(--box-shadow-color);
  transition: width 0.3s; /* Smooth transition */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
}

.side-nav.collapsed {
  width: 60px;
}

.toggle-button {
  margin: 0px;
  background: none;
  border: none;
  color: var(--header-text-color);
  cursor: pointer;
  font-size: 20px;
}

.toggle-button:focus {
  outline: none;
}

select {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--dropdown-background-color);
  color: var(--dropdown-text-color);
}

select:focus {
  outline: none;
  border-color: #007bff;
}

/* Selected Section with Rounded Border */
.side-nav > ul > li.selected {
  border-left: 4px solid #007bff; /* Rounded line with your chosen color */
  border-radius: 0 20px 20px 0; /* Rounded on the right side */
  background-color: var(--side-nav-selected-background-color); /* Background for selected item */
}

.side-nav > ul > li.selected:hover {
  background-color: var(--side-nav-selected-background-color); /* Hover effect for selected item */
}

/* Navigation List Styles */
.side-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%; /* Full width */
}

.side-nav > ul > li {
  display: flex;
  flex-direction: column; /* Stack nested items vertically */
  align-items: center; /* Center items horizontally */
  font-size: 18px;
  padding: 10px 0;
  cursor: pointer;
  color: var(--side-nav-text-color);
  border-radius: 0;
  transition: background-color 0.3s, border-radius 0.3s;
  width: 100%; /* Full width */
  position: relative;
}

.side-nav > ul > li:hover {
  background-color: var(--side-nav-hover-background-color);
  border-radius: 0 20px 20px 0;
}

.side-nav > ul > li > div {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center; /* Center icon and text by default */
  transition: justify-content 0.3s, color 0.3s ease; /* Added color transition */
  color: var(--side-nav-group-text-color); /* Apply the new CSS Variable */
}

.side-nav > ul > li.expanded > div {
  justify-content: flex-start; /* Left-align icon and text when expanded */
  padding-left: 20px; /* Add padding to the left for alignment */
}

.side-nav > ul > li .icon {
  margin-right: 10px;
  transition: font-size 0.3s; /* Smooth transition for icon size */
  color: var(--side-nav-icon-color);
}

.side-nav.collapsed > ul > li .icon {
  font-size: 24px; /* Increase icon size when collapsed */
}

.nested {
  display: none; /* Hide nested items initially */
  flex-direction: column;
  align-items: center; /* Center nested items */
  padding-left: 0; /* Remove indentation for nested items */
}

.side-nav > ul > li.expanded .nested {
  display: flex; /* Show nested items when the category is expanded */
}

.nested li {
  font-size: 16px;
  padding: 5px 0;
  cursor: pointer;
  color: var(--side-nav-text-color);
  border-radius: 0;
  transition: background-color 0.3s, border-radius 0.3s;
  text-align: center; /* Center text */
  width: 100%; /* Full width */
}

.nested li:hover {
  background-color: var(--side-nav-hover-background-color);
  border-radius: 20px;
}

.nested li a {
  text-decoration: none;
  color: var(--side-nav-text-color);
  display: block;
  width: 100%; /* Full width */
}

.nested li a:hover {
  color: #007bff;
}

/* Dark Mode Toggle Button Styles */
.side-nav-footer {
  margin-top: auto; /* Push the footer to the bottom */
  padding: 20px 0;
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-mode-toggle-button {
  background-color: var(--dark-mode-toggle-background);
  color: var(--button-text-color);
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark-mode-toggle-button:hover {
  background-color: var(--dark-mode-toggle-hover-background);
}

.dark-mode-toggle-button:focus {
  outline: none;
}

.dark-mode-icon {
  margin-right: 8px;
}

.dark-mode-text {
  font-size: 16px;
}

/* Rooftop Selector in Sidebar */
.rooftop-selector-item {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}

.rooftop-popover {
  position: absolute;
  left: 150px; /* Adjust this based on sidebar width */
  top: 0;
  background-color: var(--side-nav-background-color);
  box-shadow: 0px 8px 8px var(--box-shadow-color);
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
  width: 300px;
  display: flex;
  flex-direction: column;
}

.side-nav.collapsed .rooftop-popover {
  left: 60px; /* Adjust this based on collapsed sidebar width */
  width: 300px;
}

.rooftop-option {
  padding: 8px;
  cursor: pointer;
  color: var(--dropdown-text-color);
}

.rooftop-option:hover {
  background-color: var(--option-hover-background-color, #e9e9e9); /* Change background on hover */
}

.rooftop-option:active {
  background-color: var(--option-active-background-color, #d9d9d9); /* Active state color */
}

.rooftop-popover .selected {
  font-weight: bold;
  color: var(--selected-color, #0056b3); /* Highlight selected option */
}

.side-nav-item {
  display: flex;
  align-items: center;
  width: 100%;
}

.side-nav-dropdown {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: var(--dropdown-background-color);
  color: var(--dropdown-text-color);
  width: 100%;
}

.side-nav-dropdown:focus {
  outline: none;
  border-color: #007bff;
}

/* Rooftop Title */
.rooftop-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: var(--title-text-color);
}

/* Adjust rooftop title for smaller screens */
@media (max-width: 768px) {
  .rooftop-title {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .rooftop-title {
    font-size: 1.2rem;
  }
}

/* Error Message Styles */
.error-message {
  position: fixed;
  top: 80px; /* Below the header */
  right: 20px;
  background-color: #ffdddd;
  color: #d8000c;
  padding: 10px 20px;
  border: 1px solid #d8000c;
  border-radius: 4px;
  z-index: 1001;
}

/* Loading Spinner Styles (if not already defined) */
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Ensure select is styled consistently */
.side-nav-dropdown {
  appearance: none; /* Remove default styling */
  /* background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="10" height="5"%3E%3Cpath fill="%23999" d="M0 0l5 5 5-5z"/%3E%3C/svg%3E'); */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 10px 5px;
  padding-right: 20px;
}

/* Optional: Hover and focus styles for better UX */
.side-nav-dropdown:hover {
  border-color: #007bff;
}

.side-nav-dropdown:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Content Area Styles */
.content {
  flex: 1;
  padding: 20px;
  background-color: var(--content-background-color);
  color: var(--neutral-text-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px var(--box-shadow-color);
  margin-left: 150px;
  transition: margin-left 0.3s, background-color 0.3s ease, color 0.3s ease;
}

.content.expanded {
  margin-left: 150px; /* Space for expanded sidebar */
}

.content.collapsed {
  margin-left: 60px; /* Space for collapsed sidebar */
}

/* 4. Modify Media Queries to Allow Sidebar Expansion on Small Screens */
@media (max-width: 768px) {
  .side-nav {
    width: 60px; /* Default collapsed width on small screens */
  }

  .side-nav.collapsed {
    width: 60px; /* Maintain collapsed width */
  }

  /* When sidebar is expanded on small screens */
  .side-nav:not(.collapsed) {
    width: 150px; /* Expanded width */
  }

  .content {
    margin-left: 60px; /* Align content with collapsed sidebar */
  }

  .content.collapsed {
    margin-left: 60px; /* Keep content aligned even when sidebar is "collapsed" */
  }

  .header-left .title {
    font-size: 20px; /* Adjust title size for smaller screens */
  }

  .header-right {
    flex: 0; /* Adjust flex properties for header elements */
  }

  /* Adjust dark mode toggle button width on small screens */
  .dark-mode-toggle-button {
    width: 70%;
  }

  .dark-mode-text {
    font-size: 14px;
  }
}

/* 5. Further Adjustments for Very Small Screens */
@media (max-width: 480px) {
  .title {
    font-size: 18px;
  }

  .side-nav > ul > li {
    font-size: 16px;
  }

  .side-nav > ul > li .icon {
    font-size: 20px;
  }

  .nested li {
    font-size: 14px;
  }

  .dark-mode-toggle-button {
    padding: 6px 10px;
    font-size: 14px;
  }

  .dark-mode-text {
    display: none; /* Hide text on very small screens to save space */
  }
}

/* Example styles for the notification bell and dropdown */
.notification-bell-container {
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  color: var(--neutral-text-color);
}

.notification-bell-icon {
  font-size: 1.2rem;
  color: var(--header-text-color);
}

.notification-bell-badge {
  position: absolute;
  top: -6px;
  right: -10px;
  background: red;
  color: var(--header-text-color);
  border-radius: 50%;
  padding: 0px 6px;
  font-size: 0.7rem;
}

.notification-dropdown {
  position: absolute;
  top: 2rem;
  right: 0;
  width: 250px;
  background: var(--side-nav-background-color);
  border-radius: 10px;
  z-index: 999;
  padding: 0.5rem;
}

.notification-item {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-actions {
  margin-top: 0.5rem;
}
.approve-btn {
  background-color: #4caf50;
  color: var(--dropdown-hover-background-color);
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 0.5rem;
}
.approve-btn:hover {
  background-color: #45a049;
}
.reject-btn {
  background-color: #f44336;
  color: var(--dropdown-hover-background-color);
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  cursor: pointer;
}
.reject-btn:hover {
  background-color: #d73b31;
}

.review-btn {
  background-color: var(--toggle-button-background-color);
  color: var(--dropdown-hover-background-color);
  border: none;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 5px;
}
.review-btn:hover {
  background-color: var(--toggle-button-hover-background-color);
}
