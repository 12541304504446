/* LoadingAnimation.css */

body {
    cursor: default;
}

.loading-container {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: var(--background-color); /* Use CSS Variable */
    overflow: hidden;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.car {
    position: absolute;
    top: 50px;
    left: -150px;
    display: flex;
    align-items: center;
    animation: drive 5s infinite linear;
}

.car svg {
    color: var(--loading-car-color); /* Use CSS Variable */
    transition: color 0.3s ease; /* Smooth transition */
}

.smoke {
    position: absolute; /* Use absolute positioning */
    width: 20px;
    height: 20px;
    background-color: var(--loading-smoke-color); /* Use CSS Variable */
    border-radius: 50%;
    left: -30px; /* Move smoke behind the car */
    animation: smoke 1s infinite ease-in-out;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.loading-text {
    position: absolute;
    top: 100px;
    left: 50px;
    font-size: 24px;
    font-weight: bold;
    color: var(--loading-text-color); /* Use CSS Variable */
    opacity: 0;
    animation: reveal-text 3s forwards ease-in-out;
    transition: color 0.3s ease; /* Smooth transition */
}

/* Animation for the car moving */
@keyframes drive {
    0% {
        left: -150px;
    }
    100% {
        left: 100%;
    }
}

/* Smoke effect */
@keyframes smoke {
    0% {
        opacity: 0;
        transform: translateY(10px) scale(0.5);
    }
    50% {
        opacity: 1;
        transform: translateY(-20px) scale(1);
    }
    100% {
        opacity: 0;
        transform: translateY(-40px) scale(0.5);
    }
}

/* Text reveal effect */
@keyframes reveal-text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
