/* FPReconReport.css */

/* Container for the entire FP Recon report */
.fp-recon-report {
    margin: 2rem auto;
    padding: 1.5rem;
    max-width: 1000px; /* Wider container for discrepancies table */
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  /* Header section */
  .header-section {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  /* Summary Box */
  .summary-box {
    background-color: #e6f7ff;
    padding: 1rem;
    margin: 1rem auto;
    width: 50%;  /* Reduced width for summary box */
    border-radius: 4px;
    border: 1px solid #b3daff;
  }
  
  /* Each line in the summary box */
  .summary-line {
    display: flex;
    justify-content: space-between;
    margin: 0.3rem 0;
  }
  
  /* Horizontal separator line */
  hr {
    border: none;
    border-top: 1px solid #ccc;
    margin: 8px 0;
  }
  
  /* Container for narrow selectors: only affects the controls for month and file selectors */
  /* Styles only for the narrow selector controls with label on top */
.control-inline {
    display: flex;
    gap: 20px;
    justify-content: center; /* Center the group of narrow selectors */
    align-items: flex-start;
  }
  
  .narrow-selector-container {
    display: flex;
    flex-direction: column; /* Stack label above input */
    align-items: center;
    gap: 5px;
    max-width: 250px;
    width: 100%;
    margin-bottom: 35px;
  }
  
  .narrow-selector-container > label {
    font-weight: bold;
    text-align: center;
    margin: 0;
    width: 100%;
  }
  
  .narrow-selector-container > input,
  .narrow-selector-container > select {
    width: 100%;
    padding: 6px 10px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  