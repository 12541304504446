/* FPReconReport.css */

/* Container for the entire report */
.fp-recon-report {
  margin: 2rem auto;
  padding: 1.5rem;
  max-width: 1000px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

/* Header section */
.header-section {
  text-align: center;
  margin-bottom: 1rem;
}
.header-section h3 {
  margin: 0.5rem 0 0;
  color: #555;
}

/* 'As of:' styling */
.header-section > div {
  margin-top: 0.5rem;
  font-style: italic;
}

/* Summary Box */
.summary-box {
  background-color: #e6f7ff;
  padding: 1rem;
  margin: 1rem auto;
  width: 50%;
  border-radius: 4px;
  border: 1px solid #b3daff;
}
.summary-line {
  display: flex;
  justify-content: space-between;
  margin: 0.3rem 0;
}
.summary-line .label {
  font-weight: bold;
}

/* Download Button */
.download-btn {
  display: block;
  margin: 0.5rem 0 1rem auto;
  padding: 8px 16px;
  background-color: #3086e0;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.download-btn:hover {
  background-color: #2672ba;
}

/* 
========================================
Tab Buttons 
========================================
*/
.tab-buttons {
  /* Use flex or inline-flex to keep tabs on the same row */
  display: inline-flex; 
  margin-top: 1rem;
  position: relative;
  height: 45px; 
  /* This ensures they won't wrap onto a new line, 
     but you can add 'flex-wrap: nowrap;' or 'white-space: nowrap;' if needed */
  flex-wrap: nowrap; 
  margin-left: 20px;
}

.tab-buttons {
  display: inline-flex;
  margin-top: 1rem;
  position: relative;
  height: 45px; 
  flex-wrap: nowrap;
}

/* Inactive tab styling */
.tab-button {
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 10px 20px;
  background-color: #eee;
  border: 1px solid #ccc;
  font-weight: 500;
  margin-right: -2px; /* slight overlap */
  border-bottom: 2px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #333; /* Inactive tab text color */
}

.tab-button:hover {
  background-color: #ddd;
}

/* Remove right border from all but the last tab */
.tab-button:not(:last-child) {
  border-right: none;
}

/* Active tab: black lettering, top-lift, blue underline */
.tab-button.active {
  background-color: #fff;
  font-weight: bold;
  color: #000; /* black lettering for active tab */
  z-index: 2;

  /* Make it appear slightly taller than inactive tabs */
  height: 60px;           /* or you can do 47px, 50px, etc. */
  margin-top: -15px;       /* lift it a bit */
  padding-top: 12px;      /* if you want more text space inside */

  border-bottom: 2px solid #3086e0;  /* bright-blue underline */
}

/* 
========================================
Discrepancies Table 
========================================
*/
.discrepancies-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0rem;
  background-color: #fff;
}
.discrepancies-table thead {
  background-color: #eee;
}
.discrepancies-table th,
.discrepancies-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  line-height: 1.2;
}
.discrepancies-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
}

/* 
========================================
Matches Table (same style) 
========================================
*/
.matches-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 0rem;
  background-color: #fff;
}
.matches-table thead {
  background-color: #eee;
}
.matches-table th,
.matches-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  line-height: 1.2;
}
.matches-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  cursor: pointer;
}

/* By default, you had margin-top: 1rem. Let's reduce that to 0.5rem or 0.2rem */
.discrepancies-table,
.matches-table {
  margin-top: 0; /* previously 1rem */
}

.table-heading {
  margin: 0; /* top, left-right, bottom */
  /* or even margin: 0 if you want no gap at all */
}