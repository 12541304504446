/* LineChartD3.css */

.chart-wrapper {
  position: relative;
  background-color: var(--side-nav-background-color); /* Use CSS Variable */
  padding: 20px;
  border-radius: 10px;
  /* box-shadow: 0 2px 4px var(--tooltip-box-shadow-color); Use CSS Variable */
  width: 100%; /* Ensure the wrapper takes up 100% of available width */
  height: auto; /* Let the height adjust automatically */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.chart-title {
  font-size: 1.5rem;
  color: var(--chart-text-color); /* Use CSS Variable */
  margin: 0;
  transition: color 0.3s ease; /* Smooth transition */
}

/* Make the SVG itself responsive */
.svg-content-responsive {
  width: 100%;
  height: auto;
  transition: width 0.3s ease, height 0.3s ease; /* Optional: Smooth transitions */
}

/* Style the text for the axis labels */
.axis text {
  font-family: Arial, sans-serif;
  font-size: 14px;
  fill: var(--chart-text-color); /* Use CSS Variable */
  font-weight: normal;
  text-transform: none;
  font-variant-numeric: normal;
  letter-spacing: normal;
  transition: fill 0.3s ease; /* Smooth transition */
}

/* Style the lines for the ticks */
.axis line {
  stroke: var(--axis-line-color); /* Use CSS Variable */
  stroke-width: 1.5px;
  transition: stroke 0.3s ease; /* Smooth transition */
}

/* Style the main axis lines */
.axis path {
  stroke: var(--axis-line-color); /* Use CSS Variable */
  stroke-width: 1.5px;
  transition: stroke 0.3s ease; /* Smooth transition */
}

/* Tooltip Styling */
.tooltip {
  position: absolute;
  text-align: left;
  width: auto;
  padding: 10px;
  font-size: 12px;
  background-color: var(--tooltip-background-color); /* Use CSS Variable */
  color: var(--tooltip-text-color); /* Use CSS Variable */
  border-radius: 5px;
  pointer-events: none;
  box-shadow: var(--tooltip-box-shadow); /* Use CSS Variable */
  opacity: 0;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

/* Toggle Switch Styling */
.toggle-switch {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--slider-background-color); /* Use CSS Variable */
  transition: background-color 0.4s ease;
  border-radius: 25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  border-radius: 50%;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: transform 0.4s ease;
}

input:checked + .slider {
  background-color: var(--slider-checked-background-color); /* Use CSS Variable */
}

input:checked + .slider:before {
  transform: translateX(25px);
}

.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Optional: Focus State for Toggle Switch */
.switch input:focus + .slider {
  box-shadow: 0 0 2px 2px var(--slider-checked-background-color); /* Use CSS Variable */
}
