/* src/pages/ChangePasswordPage.css */

.change-password-page {
  position: relative;
  min-height: 100vh;
  /* background-color: #f0f2f5; */
  background-image: url('./login_background.svg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* Full-screen container for the particle background */
.change-password-background-container {
  position: fixed; /* Ensure it spans the entire viewport */
  /* top: 0;
  left: 0; */
  width: 100vw;
  height: 100vh;
  z-index: 0; /* Behind the change-password container */
}

/* Centered change password container */
.change-password-container {
  position: relative;
  z-index: 2;
  /* background-color: #ffffff; */
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 250px;
  max-width: 350px;
  margin: auto;
  text-align: center;
}

/* Logo styling */
.login-logo {
  display: block;
  margin: 0 auto 20px;
  height: 80px;
  cursor: default;
  user-select: none;
}

/* Title styling */
.change-password-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #003366;
}

/* Form styling */
.change-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.change-password-form input {
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Password field container */
.change-password-field {
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  height: 40px;
}

.change-password-field input {
  width: 100%;
  padding-right: 40px; /* space for the toggle icon */
  height: 100%;
  box-sizing: border-box;
}

/* Vertically center the password toggle icon */
.change-password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 22px;
  color: #c0c0c0;
}

/* Button styling */
.change-password-form button {
  width: 60px;
  max-width: 150px;
  padding: 10px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.change-password-form button:hover {
  background-color: #0056b3;
}

/* Error message styling */
.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
