.upload-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    font-family: sans-serif;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }  
  
  .header-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start; /* Align items to the top */
    margin-bottom: 20px;
  }
  
  /* Make each header-row child display as a column so label is above input */
  .header-row > div {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
  }
  
  .header-row label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Optional: Style the month input to make it more obvious it's interactive */
  input[type="month"] {
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
  }
  
  .selected-files {
    margin: 10px 0;
    padding: 10px;
    border: 1px dashed #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
  }
  
  .selected-files h3 {
    margin-top: 0;
  }
  
  .required-list,
  .files-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .required-list li,
  .files-list li {
    margin-bottom: 5px;
  }
  
  .required-list li {
    display: flex;
    align-items: center;
    font-size: 1rem;
  }
  
  .required-list li .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
    flex-shrink: 0;
  }
  
  .required-list li.submitted .icon {
    background-color: #4caf50;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23fff' d='M6.173 11.414l-3.88-3.88 1.414-1.414 2.466 2.466 5.293-5.293 1.414 1.414z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .required-list li.not-submitted .icon {
    border: 2px solid #ccc;
  }
  
  .files-list li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .files-list li .file-name {
    flex-grow: 1;
    margin-right: 20px;
    word-break: break-all;
  }
  
  .files-list li .actions {
    display: flex;
    gap: 10px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    border: none;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  button:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  input[type="file"] {
    margin: 10px 0;
  }
  