/* AccountingReportsDashboard.css */

.landing-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

.landing-header {
  text-align: center;
  padding: 40px 20px;
  background: #007bff;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

.landing-header h1 {
  margin: 0 0 10px;
  font-size: 2.5rem;
}

.landing-header p {
  margin: 0 0 20px;
  font-size: 1.2rem;
}

.dashboard-controls {
  text-align: center;
  margin-top: 10px;
}

.dashboard-content {
  margin-top: 20px;
}

.dashboard-section {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-bottom: 20px;
}

/* Center the report selector dropdown and constrain its width */
.report-selector-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.report-selector-container label {
  font-weight: bold;
}

.report-selector-container select {
  padding: 6px 10px;
  font-size: 1rem;
  max-width: 250px;
}

/* Report container can be made wider if needed */
.report-container {
  margin-top: 20px;
}

.landing-footer {
  text-align: center;
  padding: 20px 0;
  font-size: 0.9rem;
  color: #666;
}
