/* CollapsibleSection.css */

/* Container Styling */
.collapsible-section {
    margin-bottom: 20px;
    border: 1px solid var(--table-border-color);
    border-radius: 8px; /* Match the container's border-radius */
    overflow: hidden;
    background-color: var(--side-nav-hover-background-color); /* Ensure background matches */
    box-shadow: 0 2px 4px var(--box-shadow-color); /* Optional: Add subtle shadow */
}

/* Header Styling */
.collapsible-header {
    background-color: var(--table-header-background);
    color: var(--table-header-text);
    padding: 10px 15px;
    font-size: 1.5rem; /* Similar to h2 */
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
    /* Flex styling matches h2's flex properties */
    transition: background-color 0.3s ease;
}

/* Hover and Focus States */
.collapsible-header:hover {
    background-color: var(--button-hover-background, #e0e0e0);
}

.collapsible-header:focus {
    outline: none;
    background-color: var(--button-hover-background, #e0e0e0);
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Similar to input focus */
}

/* Icon Styling */

.collapsible-icon {
    font-size: 1.2rem; /* Adjust size as needed */
    transition: transform 0.3s ease;
}

.collapsible-icon.expanded {
    transform: rotate(180deg);
}

/* Content Styling */
.collapsible-content {
    padding: 15px;
    background-color: var(--background-color); /* Match input background */
    transition: max-height 0.3s ease;
}

/* Responsive Styling */
@media (max-width: 992px) {
    .collapsible-header {
        font-size: 1.3rem;
    }

    .collapsible-icon {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .collapsible-header {
        font-size: 1.1rem;
    }

    .collapsible-icon {
        font-size: 0.9rem;
    }
}

@media (max-width: 576px) {
    .collapsible-header {
        font-size: 1rem;
    }

    .collapsible-icon {
        font-size: 0.8rem;
    }
}
