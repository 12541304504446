/* src/pages/NotAuthorizedPage.css */

.not-authorized-page {
    text-align: center;
    padding: 50px;
  }
  
  .not-authorized-page h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .not-authorized-page p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .not-authorized-page a {
    color: #007bff;
    text-decoration: none;
  }
  
  .not-authorized-page a:hover {
    text-decoration: underline;
  }
  