/* SalesDashboard.css */

/* Align Personnel Cards Horizontally */
.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: var(--side-nav-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.sale-type-toggle {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.sale-type-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 20px; /* Makes it rounded */
  background-color: var(--sale-type-button-background-color); /* Use CSS Variable */
  color: var(--sale-type-button-text-color); /* Use CSS Variable */
  cursor: pointer;
  /* border: 1px solid white; */
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Hide the default radio button */
.sale-type-button input[type="radio"] {
  display: none;
}

/* When a button is selected, change its background and text color */
.sale-type-button.selected {
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border: 1px solid var(--button-background-color); /* Use CSS Variable */
}

.sale-type-button:hover {
  background-color: var(--sale-type-button-hover-background-color); /* Use CSS Variable */
  color: var(--sale-type-button-hover-text-color); /* Use CSS Variable */
}

/* Personnel Container */
.personnel-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Wrap personnel cards on smaller screens */
}

/* Bottom section with chart and metrics */
.bottom-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Make bottom container responsive */
}

/* Ensure space between Total Deals and Gross Profit components */
.metric-component .total-deals{
  margin-bottom: 20px; /* Adds spacing between components */
}

/* Adjust specific styling for Gross Profit if needed */
.gross-profit {
  margin-top: 20px; /* Extra spacing if needed */
}

/* Chart and Metrics Containers */
.chart-container, .metrics-container {
  flex: 1; /* Allow flexible resizing */
  min-width: 200px; /* Set a minimum width to prevent shrinking too much */
  background-color: var(--background-color); /* Use CSS Variable */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Match box shadow */
  margin-bottom: 20px; /* Add some spacing when stacked */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

/* Chart Header */
.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.chart-title {
  color: var(--chart-title-color); /* Use CSS Variable */
  font-size: 1.5rem;
  font-weight: bold;
  transition: color 0.3s ease; /* Smooth transition */
}

.custom-tooltip-average {
  position: absolute;
  background-color: var(--tooltip-background-color); /* Use CSS Variable */
  color: var(--tooltip-text-color); /* Use CSS Variable */
  padding: 8px;
  border-radius: 4px;
  top: 20px; /* Adjust based on your design */
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

/* Toggle Switch Styling */
.toggle-switch {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--switch-slider-background-color); /* Use CSS Variable */
  transition: background-color 0.4s ease;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: transform 0.4s ease;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--switch-slider-checked-background-color); /* Use CSS Variable */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* General Card Styling for Consistency */
.bg-card {
  background-color: var(--side-nav-background-color); /* Use CSS Variable */
  color: var(--text-color); /* Use CSS Variable */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Match box shadow */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

/* Compact Personnel Card Styling */
.personnel-card-compact {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1; /* Allow flexible resizing */
  width: 320px;
  padding: 10px;
  background-color: var(--background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 120px;
  min-width: 200px; /* Prevent cards from shrinking below 200px */
  max-width: 320px; /* Set max width to maintain structure */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.personnel-card-header {
  font-size: 1.3rem;
  color: var(--text-color); /* Use CSS Variable */
  margin-bottom: 8px;
  transition: color 0.3s ease; /* Smooth transition */
}

.personnel-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.personnel-name {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.metric-display {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

.personnel-row button, .metric-display button {
  width: 25px;  /* Set a fixed width */
  height: 25px; /* Set a fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: white; /* Consider using a CSS Variable */
  padding: 8px;  /* Adjust padding as needed */
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.personnel-row button:hover, .metric-display button:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
  color: var(--button-hover-background-color); /* Use CSS Variable or a separate variable for text color on hover */
}

.metric p {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.metric span {
  font-size: 1.3rem;
  color: var(--text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

/* Month Selector with Title */
.month-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

/* Title Styling */
.sales-analytics-title {
  font-size: 28px;
  font-weight: bold;
  color: var(--sales-analytics-title-color); /* Use CSS Variable */
  text-align: center;
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Smooth transition */
}

/* Month Navigation Styling */
.month-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Adds space between buttons and the month */
}

.month-button {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center both text and icon */
  justify-content: center; /* Center both text and icon */
  background-color: var(--month-button-background-color); /* Use CSS Variable */
  color: var(--month-button-text-color); /* Use CSS Variable */
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 100px; /* Set a fixed width */
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.month-button div {
  margin-bottom: 5px; /* Adds space between text and arrow */
}

.month-button:hover {
  background-color: var(--month-button-hover-background-color); /* Use CSS Variable */
  color: var(--month-button-hover-text-color); /* Use CSS Variable */
}

@media (max-width: 600px) {
  .month-button {
    width: 120px; /* Slightly smaller width on mobile screens */
    padding: 8px 10px; /* Adjust padding for smaller screens */
  }
}

.selected-month {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

/* Sales Dashboard Padding */
.sales-dashboard {
  padding: 20px;
  background-color: var(--dashboard-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease; /* Smooth transition */
}

/* Dashboard Layout */
.dashboard-components {
  display: flex;
  flex-direction: column;
}

.top-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.bottom-row {
  display: flex;
  justify-content: space-between;
}

.chart-section, .metrics-section {
  width: 48%;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  /* Stack personnel cards vertically */
  .personnel-container {
    flex-direction: column;
    align-items: center;
  }

  /* Stack chart and metrics sections */
  .bottom-container {
    flex-direction: column;
  }

  .chart-container, .metrics-container {
    width: 100%; /* Full width on smaller screens */
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .personnel-card-compact {
    width: 100%; /* Full width on very small screens */
  }
}
