/* CompPlan.css */

/* Container Styling */
.comp-plan-container {
    margin: 20px auto;
    padding: 20px;
    max-width: 1200px;
    background-color: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 4px 6px var(--box-shadow-color);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: var(--text-color);
}

/* Header Styling */

h2 {
    font-size: 1.75rem;
    color: var(--chart-text-color);
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

/* Section Totals Styling */
/* Commission Header Styling */
.commission-header {
    display: flex;
    flex-direction: row; /* Arrange title and totals horizontally */
    align-items: center; /* Vertically center the items */
    justify-content: space-between; /* Space between title and totals */
    margin-bottom: 10px; /* Space below the header */
    float: right;
}

.section-totals {
    display: flex;
    gap: 20px; /* Space between the sum totals */
    font-size: 1rem; /* Adjust as needed */
    color: var(--chart-text-color); /* Match existing text color */
}

.totals-row {
    display: flex;
    align-items: center; /* optional, if you want them aligned in the middle */
    gap: 1rem;          /* spacing between them */
    margin-bottom: 1rem; /* some space below before the table */
}
  
/* Adds a vertical line separator to the left of the second span */
.totals-separator {
    border-left: 2px solid #ccc; 
    padding-left: 1rem; /* space between border and text */
}

/* Table Styling */
.comp-plan-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
}

.comp-plan-table th,
.comp-plan-table td {
    padding: 12px 15px;
    text-align: center;
}

.comp-plan-table th {
    background-color: var(--chart-background-color);
    font-weight: bold;
    color: var(--text-color);
    border-bottom: 2px solid var(--table-border-color);
}

.comp-plan-table tbody tr {
    background-color: var(--background-color);
    transition: background-color 0.3s ease;
}

.comp-plan-table tbody tr:nth-child(even) {
    background-color: var(--background-color);
}

.comp-plan-table tbody tr:hover {
    background-color: var(--background-color);
}

.comp-plan-table td {
    border-bottom: 1px solid var(--table-border-color);
}

/* Input Styling */
.input-cell {
    width: 100%;
    padding: 8px 10px;
    font-size: 1rem;
    border: 1px solid var(--input-boarder-color);
    background-color: var(--side-nav-background-color);
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-cell:focus {
    outline: none;
    border-color: #007bff; /* Consider replacing with a CSS variable for consistency */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Select Dropdown Styling */
.input-cell[type="select"] {
    padding: 8px 10px;
}

/* Button Styling */
button {
    background-color: #007bff; /* Consider replacing with a CSS variable for consistency */
    color: #fff;
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

button:hover {
    background-color: #0056b3; /* Consider replacing with a CSS variable for consistency */
}
  

/* Responsive Design */
@media (max-width: 992px) {
    h1 {
        font-size: 2.2rem;
    }

    h2 {
        font-size: 1.5rem;
    }

    .input-cell {
        font-size: 0.95rem;
    }

    button {
        font-size: 0.95rem;
        padding: 10px 18px;
    }
}

@media (max-width: 768px) {
    .comp-plan-table th,
    .comp-plan-table td {
        padding: 10px 12px;
        font-size: 0.9rem;
    }

    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.3rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .section-totals {
        margin-top: 10px;
        gap: 10px;
    }

    .comp-plan-container {
        padding: 15px;
    }

    .commission-header {
        flex-direction: column; /* Stack title and totals vertically on smaller screens */
        align-items: flex-start;
    }

    button {
        width: 100%;
        max-width: none;
    }
}

@media (max-width: 576px) {
    .comp-plan-table th,
    .comp-plan-table td {
        padding: 8px 10px;
        font-size: 0.85rem;
    }

    h1 {
        font-size: 1.8rem;
    }

    h2 {
        font-size: 1.2rem;
    }

    .input-cell {
        font-size: 0.85rem;
    }

    button {
        font-size: 0.85rem;
        padding: 8px 16px;
    }
}

/* ----- New Styles Added Below ----- */

/* Override Styling */
.override {
    background-color: var(--background-color); /* Fallback color if variable not set */
}

/* Loading Spinner Styling */
.loading-spinner {
    margin: 20px 0;
    font-size: 1.2rem;
    color: var(--button-color, #007bff); /* Fallback to button color */
    text-align: center;
}

/* Message Styling */
.message {
    margin-top: 10px;
    color: var(--message-color, #007bff); /* Fallback to green */
    font-weight: bold;
    text-align: center;
}

/* Clear Data Button Styling */
.clear-data-button {
    background-color: #6c757d; /* Gray color, consider using a CSS variable */
    color: #fff;
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: block;
    width: 100%;
    max-width: 200px;
    margin: 10px auto 0 auto; /* Added top margin for spacing */
}

.clear-data-button:hover {
    background-color: #5a6268; /* Darker gray, consider using a CSS variable */
}

/* Optional: Highlight Overridden Inputs */
.input-cell.override {
    border: 1px solid var(--input-boarder-color);
    background-color: var(--side-nav-background-color);
}

/* ----- End of New Styles ----- */
/* Selection Section Container */
.selection-section {
    background-color: var(--background-color); /* Matches the container's background */
    color: var(--text-color); /* Ensures text is visible against the background */
    padding: 15px;
    border-radius: 4px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds subtle depth */
}

/* Form Row Styling */
.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 10px;
}

/* Form Group Styling */
.form-group {
    display: flex;
    flex-direction: column;
    flex: 1; /* Allows form groups to expand equally */
}

/* Label Styling within Selection Section */
.selection-section .form-group label {
    margin-bottom: 5px;
    font-weight: 600;
    color: var(--text-color); /* Ensures label text is visible */
}

/* Select Dropdown Styling within Selection Section */
.selection-section .form-group select {
    background-color: var(--input-background-color); /* Adapts to theme */
    color: var(--text-color); /* Ensures selected text is visible */
    border: 1px solid var(--input-border-color); /* Consistent border styling */
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Optional: Adjust Select Arrow Color for Better Visibility */
.selection-section .form-group select::-ms-expand {
    background-color: transparent;
    color: var(--text-color);
}

.selection-section .form-group select:focus {
    outline: none;
    border-color: #007bff; /* You can replace this with a CSS variable if preferred */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* ----- New Styles for Button Group ----- */

/* Button Group Styling */
.button-group {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    gap: 10px; /* Space between buttons */
    margin-top: 10px; /* Space above the button group */
}

/* Adjust Button Widths for Better Alignment */
.fetch-data-button,
.clear-data-button {
    flex: 1; /* Make buttons take equal width */
    max-width: 150px; /* Optional: Set a maximum width */

    background-color: #007bff; /* Consider replacing with a CSS variable for consistency */
    color: #fff;
    padding: 12px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    margin: 0 auto;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .button-group {
        flex-direction: column; /* Stack buttons vertically on smaller screens */
    }

    .fetch-data-button,
    .clear-data-button {
        max-width: 100%; /* Allow buttons to take full width */
    }
}