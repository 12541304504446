/* SalesReport.css */

.filter-calendar {
  background-color: var(--leaderboard-background-color); /* Use CSS Variable */
  /* border: 1px solid var(--leaderboard-table-border-color); Use CSS Variable */
  border-radius: 10px;
  padding: 5px;
}

.sales-report-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: var(--sales-report-background-color); /* Use CSS Variable */
  border-radius: 8px;
  box-shadow: var(--sales-report-box-shadow); /* Use CSS Variable */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.sales-report-container h2 {
  text-align: center;
  color: var(--sales-report-heading-color); /* Use CSS Variable */
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.sales-report-container .totals-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;
}

.sales-report-container .totals-section {
  flex: 1;
  margin: 0 10px;
  padding: 20px;
  background-color: var(--sales-report-section-background-color); /* Use CSS Variable */
  border: 1px solid var(--sales-report-section-border-color); /* Use CSS Variable */
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.sales-report-container .totals-section h3 {
  font-size: 24px;
  color: var(--sales-report-section-heading-color); /* Use CSS Variable */
  margin-bottom: 15px;
  border-bottom: 2px solid var(--sales-report-section-heading-border-color); /* Use CSS Variable */
  padding-bottom: 10px;
  transition: color 0.3s ease, border-bottom-color 0.3s ease;
}

.sales-report-container .totals-section p {
  font-size: 20px; /* Larger font size */
  font-weight: bold; /* Bold text */
  color: var(--sales-report-section-text-color); /* Use CSS Variable */
  margin: 5px 0;
  transition: color 0.3s ease;
}

.table-wrapper {
  overflow-x: auto;
}

.sales-report-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.sales-report-table th,
.sales-report-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid var(--sales-report-table-border-color); /* Use CSS Variable */
  transition: border-bottom-color 0.3s ease;
}

.sales-report-table th {
  background-color: var(--sales-report-table-header-background-color); /* Use CSS Variable */
  color: var(--sales-report-table-header-text-color); /* Use CSS Variable */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.sales-report-table tr:nth-child(even) {
  background-color: var(--sales-report-table-row-even-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.sales-report-table tr:nth-child(odd) {
  background-color: var(--sales-report-table-row-odd-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.sales-report-table tr:hover {
  background-color: var(--sales-report-table-row-hover-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.sales-report-table td {
  color: var(--sales-report-table-cell-text-color); /* Use CSS Variable */
  transition: color 0.3s ease;
}

.even-row {
  background-color: var(--sales-report-even-row-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.odd-row {
  background-color: var(--sales-report-odd-row-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

/* Export Section */
.export-section {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.download-button {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: var(--button-hover-background-color);
}

/* Dropdown Menu */
.dropdown-menu {
  position: absolute;
  top: 45px; /* Adjust based on button height */
  right: 0;
  background-color: var(--sales-report-section-background-color); /* Use appropriate variable */
  border: 1px solid var(--sales-report-table-border-color);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 1000;
}

.dropdown-item {
  background-color: var(--sales-report-section-background-color);
  color: var(--sales-report-table-cell-text-color);
  padding: 10px 20px;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-item:hover {
  background-color: var(--sales-report-table-row-hover-background-color);
}

/* Adjust Export Section for Responsiveness */
@media (max-width: 768px) {
  .export-section {
    width: 100%;
    text-align: center;
  }
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .sales-report-container {
    padding: 10px;
  }

  .sales-report-table th,
  .sales-report-table td {
    padding: 8px 10px;
  }

  .sales-report-container h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 500px) {
  .sales-report-container h2 {
    font-size: 20px;
  }

  .sales-report-container .totals-section h3 {
    font-size: 20px;
  }

  .sales-report-table th,
  .sales-report-table td {
    font-size: 12px;
    padding: 6px;
  }

  .sales-report-container .totals-section p {
    font-size: 16px; /* Adjust font size for very small screens */
  }
}
