/* InventoryDashboard.css */
.inventory-dashboard {
    max-width: 1000vm;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .inventory-dashboard h2,
  .inventory-dashboard h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .dashboard-header {
    font-size: 24px;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .sale-type-filter {
    display: flex;
    gap: 10px;
    background-color: #e0e0e0;
    padding: 10px;
    border-radius: 8px;
  }
  
  .sale-type-filter label {
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .sale-type-filter input[type="radio"] {
    margin-right: 5px;
  }
  
  .chart-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .chart-item {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  
  .slider-label {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .range-track {
    height: 4px;
    background: linear-gradient(to right, #c0c0c0, #007bff);
    width: 100%;
    border-radius: 4px;
    position: relative;
  }
  
  .range-thumb {
    height: 10px;
    width: 10px;
    background-color: #007bff;
    border-radius: 50%;
    box-shadow: 0 0 0 3px #fff, 0 0 0 5px #c0c0c0;
    cursor: pointer;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  
  .date-range-display {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #666;
    margin-top: 10px;
  }
  
  .date-range-display span {
    cursor: pointer;
  }
  
  .date-range-display input {
    max-width: 150px;
    padding: 5px;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .average-sale-price,
  .total-units {
    font-size: 18px;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
  }
  
  .average-sale-price {
    color: #007bff;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .average-price-value {
    font-weight: bold;
    font-size: 36px;
    color: #0056b3;
  }
  
  .total-units {
    color: #007bff;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .total-units-value {
    font-weight: bold;
    font-size: 36px;
    color: #0056b3;
  }
  
  @media (max-width: 600px) {
    .inventory-dashboard {
      padding: 10px;
    }
  
    .range-track,
    .date-range-display {
      width: 100%;
    }
  
    .average-sale-price,
    .total-units {
      font-size: 16px;
    }
  
    .filters {
      flex-direction: column;
      align-items: stretch;
    }
  
    .sale-type-filter {
      justify-content: center;
      margin-bottom: 20px;
    }
  
    .stats {
      flex-direction: column;
      align-items: center;
    }
  }
  