/* SalesForm.css */

.sales-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--sales-form-background-color); /* Use CSS Variable */
  border-radius: 8px;
  box-shadow: var(--form-box-shadow); /* Use CSS Variable */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.sales-form h2 {
  text-align: center;
  color: var(--sales-form-text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.sales-form h3,
.sales-form .section-header {
  cursor: pointer;
  color: var(--section-header-color); /* Use CSS Variable */
  margin-bottom: 10px;
  transition: color 0.3s ease; /* Smooth transition */
}

.sales-form .section-header {
  font-size: 24px; /* Adjust the size as needed */
  font-weight: bold; /* Make the text bold */
  margin-bottom: 15px; /* Add some space below the header */
  cursor: pointer; /* Show pointer cursor to indicate collapsible behavior */
}

.sales-form .form-section {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--divider-color); /* Use CSS Variable */
  transition: border-color 0.3s ease; /* Smooth transition */
}

.sales-form .table-layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 10px;
}

.sales-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  color: var(--sales-form-text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.sales-form input[type="text"],
.sales-form input[type="number"],
.sales-form input[type="date"],
.sales-form select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color); /* Use CSS Variable */
  background-color: var(--input-background-color); /* Optional: Use if different */
  color: var(--sales-form-text-color); /* Use CSS Variable */
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.sales-form .button-container {
  display: flex;
  align-items: center;
  gap: 20px; /* Adds spacing between the buttons */
}

.sales-form .button-container button {
  padding: 10px 20px;
  cursor: pointer;
  width: auto;
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border: none; /* Assuming no border */
  border-radius: 4px; /* Optional: Add if needed */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.sales-form .button-container button:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
}

.sales-form .button-divider {
  height: 40px; /* Adjust the height of the vertical line */
  width: 1px; /* Width of the vertical line */
  background-color: var(--divider-color); /* Use CSS Variable */
  margin: 0 20px; /* Adds padding around the line */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.sales-form button[type="submit"] {
  padding: 10px 20px;
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.sales-form button[type="submit"]:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
}

.sales-form .checkbox-container {
  display: flex;
  align-items: center;
  padding-top: 20px;
}

.sales-form .checkbox-container input[type="checkbox"] {
  width: 20px; /* Adjust width */
  height: 20px; /* Adjust height */
  transform: scale(1.5); /* Scale the checkbox size */
  margin-left: 10px; /* Space between the checkbox and label */
  accent-color: var(--checkbox-accent-color); /* Use CSS Variable */
  transition: accent-color 0.3s ease; /* Smooth transition */
}

/* Container for percentage inputs */
.percentage-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.percentage-input-container input {
  width: 100%;
  padding-right: 20px; /* Space for the percentage symbol */
}

.percentage-symbol {
  position: absolute;
  right: 10px;
  pointer-events: none; /* Allows clicks to pass through to the input */
  color: #555;
}

/* Optional: Style the percentage labels for better alignment */
.percentage-label {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* --------------------- New Table Styling --------------------- */

/* Additional Search Section styling to align dropdown and input */
.search-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.search-input-group {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  place-self: center;
}

/* Table styling (already included in your file) */
.fetched-sales-table {
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.fetched-sales-table table {
  width: 100%;
  border-collapse: collapse;
}

.fetched-sales-table th,
.fetched-sales-table td {
  padding: 10px 15px;
  /* border: 1px solid var(--divider-color); */
  text-align: center;
}

.fetched-sales-table th {
  background-color: var(--leaderboard-table-row-even-background-color, #f2f2f2);
  color: var(--text-color, #333);
  font-weight: bold;
}

.fetched-sales-table tr:nth-child(even) {
  background-color: var(--leaderboard-even-row-background-color, #fafafa);
}

.fetched-sales-table tr:hover {
  background-color: var(--leaderboard-table-row-hover-background-color, #f0f0f0);
  cursor: pointer;
}
