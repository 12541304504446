/* InventoryForm.css */

.inventory-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, sans-serif;
  color: var(--inventory-form-text-color); /* Use CSS Variable */
  padding: 20px;
  max-width: 800px;
  margin: auto;
  background-color: var(--inventory-form-background-color); /* Use CSS Variable */
  border-radius: 8px;
  box-shadow: var(--inventory-form-box-shadow); /* Use CSS Variable */
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
}

.inventory-form-container button, .inventory-form-container .centered-button {
  padding: 10px 20px;
  margin: 10px auto;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid var(--button-background-color); /* Primary button border */
  background-color: var(--button-background-color); /* Primary button background */
  color: var(--button-text-color); /* Primary button text color */
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
  display: block;
}

.inventory-form-container button:hover, .inventory-form-container .centered-button:hover {
  background-color: var(--button-hover-background-color); /* Primary button hover background */
  border-color: var(--button-hover-border-color); /* Primary button hover border */
}

.inventory-form-container button:focus, .inventory-form-container .centered-button:focus {
  outline: none;
}

.inventory-form-container .search-section, .inventory-form-container .form-data-section {
  width: 100%;
  margin-top: 20px;
}

.inventory-form-container .search-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inventory-form-container input[type="text"],
.inventory-form-container input[type="date"],
.inventory-form-container input[type="number"],
.inventory-form-container select {
  width: 100%;
  padding: 10px;
  margin: 5px 0 15px 0;
  border: 1px solid var(--input-border-color); /* Use CSS Variable */
  border-radius: 4px;
  box-sizing: border-box;
  background-color: var(--input-background-color); /* Optional: Use if different */
  color: var(--inventory-form-text-color); /* Use CSS Variable */
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.inventory-form-container label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
  color: var(--inventory-form-text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.inventory-form-container .hide-fetched-data-button,
.inventory-form-container .populate-form-button {
  margin-top: 20px;
  background-color: var(--button-secondary-background-color); /* Secondary button background */
  border: 1px solid var(--button-secondary-border-color); /* Secondary button border */
  color: var(--button-text-color); /* Secondary button text color */
  transition: background-color 0.3s, border-color 0.3s;
}

.inventory-form-container .hide-fetched-data-button:hover,
.inventory-form-container .populate-form-button:hover {
  background-color: var(--button-secondary-hover-background-color); /* Secondary button hover background */
  border-color: var(--button-secondary-border-hover-color); /* Secondary button hover border */
}

.inventory-form-container .fetched-data {
  background-color: var(--fetched-data-background-color); /* Use CSS Variable */
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
  box-shadow: var(--form-box-shadow); /* Reuse form box shadow */
  text-align: center;
  color: var(--fetched-data-text-color); /* Use CSS Variable */
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.inventory-form-container .fetched-data h3 {
  margin-top: 0;
  color: var(--inventory-heading-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.inventory-form-container .fetched-data p {
  margin: 5px 0;
  color: var(--fetched-data-text-color); /* Use CSS Variable */
  transition: color 0.3s ease; /* Smooth transition */
}

.inventory-form-container .form-header-container h2 {
  font-size: 28px; /* Larger font size */
  font-weight: bold; /* Make the text bold */
  color: var(--inventory-heading-color); /* Use CSS Variable */
  margin-bottom: 15px; /* Add some spacing below the heading */
  padding: 10px; /* Add padding around the text */
  border-bottom: 2px solid var(--inventory-divider-color); /* Use CSS Variable */
  text-align: center; /* Center the heading text */
  transition: color 0.3s ease, border-bottom-color 0.3s ease; /* Smooth transitions */
}

.inventory-form-container .form-header-container h3 {
  font-size: 16px; /* Larger font size */
  font-weight: bold; /* Make the text bold */
  color: var(--inventory-subheading-color); /* Use CSS Variable */
  margin-bottom: 15px; /* Add some spacing below the heading */
  padding: 0px; /* Add padding around the text */
  text-align: center; /* Center the heading text */
  transition: color 0.3s ease; /* Smooth transition */
}

@media (max-width: 600px) {
  .inventory-form-container {
    padding: 10px;
  }

  .inventory-form-container button, .inventory-form-container .centered-button {
    width: 100%;
    margin: 5px 0;
  }

  .inventory-form-container .fetched-data,
  .inventory-form-container .search-section,
  .inventory-form-container .form-data-section {
    width: 100%;
    margin-top: 10px;
  }

  .inventory-form-container input[type="text"],
  .inventory-form-container input[type="date"],
  .inventory-form-container input[type="number"],
  .inventory-form-container select {
    width: 100%;
  }
}
