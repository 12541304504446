/* SwitchBoardPage.css */

.switchboard-page {
    padding: 20px;
    background-color: var(--background-color); /* Adapt to theme */
    color: var(--text-color); /* Adapt to theme */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

.switchboard-table {
    width: 100%;
    border-collapse: collapse;
}

.switchboard-table th,
.switchboard-table td {
    border: 1px solid var(--table-border-color); /* Use CSS Variable */
    padding: 8px;
    vertical-align: top;
    transition: border-color 0.3s ease; /* Smooth transition */
}

.switchboard-table th {
    background-color: var(--table-header-background-color); /* Use CSS Variable */
    text-align: left;
    transition: background-color 0.3s ease; /* Smooth transition */
}

.switchboard-table th,
.switchboard-table td {
    color: var(--text-color); /* Ensure text adapts to theme */
    transition: color 0.3s ease;
}

button {
    padding: 5px 10px;
    cursor: pointer;
    background-color: var(--button-background-color); /* Use CSS Variable */
    color: var(--button-text-color); /* Use CSS Variable */
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions */
}

button:hover {
    background-color: var(--button-hover-background-color); /* Hover state */
}

button:focus {
    outline: 2px solid var(--button-hover-background-color); /* Focus state for accessibility */
    outline-offset: 2px;
}

.permission-checkbox {
    margin-bottom: 5px;
    color: var(--text-color); /* Adapt to theme */
    transition: color 0.3s ease; /* Smooth transition */
}

input[type="checkbox"] {
    margin-right: 5px;
    accent-color: var(--button-background-color); /* Modern browsers support accent-color */
    transition: accent-color 0.3s ease; /* Smooth transition */
}

/* Optional: Scoped Button Styling */
/* .switchboard-page button { */
    /* If you want to scope button styles to this page */
/* } */

/* Optional: Input and Textarea Styling for Consistency */
.switchboard-page input[type="text"],
.switchboard-page input[type="email"],
.switchboard-page textarea {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--table-border-color);
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}

.switchboard-page input[type="text"]::placeholder,
.switchboard-page textarea::placeholder {
    color: var(--text-color);
    opacity: 0.7;
}

/* Modal overlay styling */
.overlay {
    background-color: var(--background-color);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* Modal content styling */
  .modal {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 10px var(--box-shadow-color);
    position: relative;
  }
  
  /* Modal header */
  .modal h2 {
    margin-top: 0;
  }
  
  /* Modal form buttons styling */
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    margin-left: 10px;
  }
  
  /* Specific styling for modal submit button */
  .modal-submit-button {
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-submit-button:hover {
    background-color: var(--button-hover-background-color);
  }
  
  /* Specific styling for modal cancel button */
  .modal-cancel-button {
    background-color: var(--background-color);
    color: var(--button-text-color);
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-cancel-button:hover {
    background-color: var(--button-hover-background-color)
  }
  
  /* Style for the "Add New User" button on the main page */
  .add-user-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-user-button:hover {
    background-color: var(--button-hover-background-color);
  }
  
  .modal input[type="text"],
.modal input[type="email"],
.modal textarea {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 1px solid var(--table-border-color);
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
}
