/* App.css */

.app-container {
  background-color: var(--background-color);
  color: var(--text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  
  /* Smooth transitions */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app-header {
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  padding: 20px;
  text-align: center;
  
  transition: background-color 0.3s ease, color 0.3s ease;
}

.app-footer {
  background-color: var(--header-background-color);
  color: var(--header-text-color);
  padding: 10px;
  text-align: center;
  
  transition: background-color 0.3s ease, color 0.3s ease;
}
