/* InventoryData.css */

.inventory-data-container {
  max-width: 100%;
  margin: 40px auto;
  padding: 20px;
  background-color: var(--inventory-data-background-color); /* Use CSS Variable */
  border-radius: 8px;
  box-shadow: var(--inventory-data-box-shadow); /* Use CSS Variable */
  overflow-x: auto;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.inventory-data-container h2 {
  text-align: center;
  color: var(--inventory-data-heading-color); /* Use CSS Variable */
  margin-bottom: 20px;
  font-size: 28px;
  transition: color 0.3s ease;
}

/* Flexbox layout for filter section and download button */
.inventory-data-container .filter-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.inventory-data-container .filter-section .filter-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inventory-data-container .filter-section label {
  font-weight: bold;
  color: var(--inventory-data-text-color); /* Use CSS Variable */
  margin-bottom: 5px;
  transition: color 0.3s ease;
}

.inventory-data-container .filter-section input,
.inventory-data-container .filter-section select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color); /* Use CSS Variable */
  background-color: var(--input-background-color); /* Optional: Use if different */
  color: var(--input-text-color); /* Use CSS Variable */
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.inventory-data-container .filter-section button {
  padding: 10px 20px;
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.inventory-data-container .filter-section button:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
  border-color: var(--button-hover-border-color); /* Use CSS Variable */
}

/* Right alignment for the download dropdown */
.inventory-data-container .filter-section-right {
  margin-left: auto;
  position: relative;
}

/* Download dropdown container */
.inventory-data-container .download-dropdown-container {
  position: relative;
  display: inline-block;
}

/* Hidden by default, shown on hover */
.inventory-data-container .download-dropdown {
  display: none;
  position: absolute;
  background-color: var(--inventory-data-background-color); /* Use CSS Variable */
  box-shadow: var(--fetched-data-box-shadow); /* Reuse fetched-data box shadow */
  padding: 10px;
  z-index: 1;
  border-radius: 8px;
  right: 0;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.inventory-data-container .download-dropdown-container:hover .download-dropdown {
  display: block;
}

.inventory-data-container .download-dropdown button {
  padding: 10px 20px;
  margin: 5px 0;
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.inventory-data-container .download-dropdown button:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
}

/* Styling for the download icon */
.inventory-data-container .download-icon {
  font-size: 28px; /* Increase the size of the download icon */
  cursor: pointer;
  color: var(--download-icon-color); /* Use CSS Variable */
  transition: color 0.3s ease;
}

.inventory-data-container .download-icon:hover {
  color: var(--download-icon-hover-color); /* Use CSS Variable */
}

/* Table styles */
.inventory-data-container .table-wrapper {
  width: 100%;
  margin-top: 20px;
  overflow-x: auto;
}

.inventory-data-container .inventory-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.inventory-data-container .inventory-table th,
.inventory-data-container .inventory-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid var(--divider-color); /* Use CSS Variable */
  white-space: nowrap;
  transition: border-color 0.3s ease;
}

.inventory-data-container .inventory-table th {
  background-color: var(--inventory-data-heading-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Reuse button text color for contrast */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.inventory-data-container .inventory-table tr:nth-child(even) {
  background-color: var(--fetched-data-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.inventory-data-container .inventory-table tr:hover {
  background-color: var(--divider-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

/* Update Section */
.inventory-data-container .update-section {
  margin-bottom: 30px;
  background-color: var(--form-background-color); /* Reuse form background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: var(--form-box-shadow); /* Use CSS Variable */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.inventory-data-container .update-section h3 {
  color: var(--inventory-data-text-color); /* Use CSS Variable */
  margin-bottom: 20px;
  transition: color 0.3s ease;
}

.inventory-data-container .update-section label {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--inventory-data-text-color); /* Use CSS Variable */
  transition: color 0.3s ease;
}

.inventory-data-container .update-section input {
  width: 60%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color); /* Use CSS Variable */
  background-color: var(--input-background-color); /* Use CSS Variable */
  color: var(--input-text-color); /* Use CSS Variable */
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.inventory-data-container .update-section button {
  padding: 10px 20px;
  background-color: var(--button-secondary-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Reuse button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inventory-data-container .update-section button:hover {
  background-color: var(--button-secondary-hover-background-color); /* Use CSS Variable */
}

/* Pagination Styles */
.inventory-data-container .pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.inventory-data-container .pagination button {
  padding: 10px 15px;
  margin: 0 5px;
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.inventory-data-container .pagination button.active {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
}

.inventory-data-container .pagination button:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
}

/* Styling for status indicators */
.inventory-data-container .status-sold {
  color: var(--status-sold-color); /* Use CSS Variable */
  font-weight: bold;
  transition: color 0.3s ease;
}

.inventory-data-container .status-available {
  color: var(--status-available-color); /* Use CSS Variable */
  font-weight: bold;
  transition: color 0.3s ease;
}

/* Styles for the upload button */
.upload-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #3498db;
}

.upload-button:hover {
  color: #2980b9;
}

/* Styles for the hovered images tooltip */
.hovered-image-tooltip {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  /* Position the tooltip near the cursor or at a fixed position */
  top: 50px; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translateX(-50%);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.hovered-image-tooltip img {
  max-width: 200px;
  max-height: 200px;
  border-radius: 4px;
  object-fit: cover;
}

/* Ensure the container has position: relative to position the tooltip correctly */
.inventory-data-container {
  position: relative;
}

/* Optional: Style for the upload icon button */
.upload-button svg {
  width: 16px;
  height: 16px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .inventory-data-container .filter-section {
    flex-direction: column;
    align-items: stretch;
  }

  .inventory-data-container .filter-section .filter-group {
    width: 100%;
    margin-right: 0;
  }

  .inventory-data-container .filter-section button {
    margin-left: 0;
    width: 100%;
  }

  .inventory-data-container .update-section input {
    width: 100%;
  }

  .inventory-data-container .inventory-table {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .inventory-data-container .inventory-table th, 
  .inventory-data-container .inventory-table td {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .hovered-image-tooltip {
    width: 90%;
    left: 5%;
    top: 60px;
  }

  .hovered-image-tooltip img {
    max-width: 150px;
    max-height: 150px;
  }
}