/* PersonnelData.css */

.personnel-data-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color); /* Reuse global background color */
  transition: background-color 0.3s ease;
}

.personnel-data-container .personnel-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.personnel-data-container .personnel-table th,
.personnel-data-container .personnel-table td {
  padding: 10px;
  border: 1px solid var(--table-border-color); /* Use CSS Variable */
  text-align: left;
  transition: border-color 0.3s ease;
}

.personnel-data-container .personnel-table th {
  background-color: var(--table-header-background-color); /* Use CSS Variable */
  color: var(--table-header-text-color); /* Use CSS Variable */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.personnel-data-container .personnel-table tr:nth-child(even) {
  background-color: var(--table-row-even-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.personnel-data-container .personnel-table tr:hover {
  background-color: var(--table-row-hover-background-color); /* Use CSS Variable */
  transition: background-color 0.3s ease;
}

.personnel-data-container .update-section,
.personnel-data-container .add-section {
  margin-top: 20px;
}

.personnel-data-container .update-section h3,
.personnel-data-container .add-section h3 {
  margin-bottom: 10px;
  color: var(--text-color); /* Reuse global text color */
  transition: color 0.3s ease;
}

.personnel-data-container .update-section label,
.personnel-data-container .add-section label {
  display: block;
  margin-bottom: 5px;
  color: var(--label-color); /* Use CSS Variable */
  transition: color 0.3s ease;
}

.personnel-data-container .update-section input,
.personnel-data-container .add-section input {
  padding: 8px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--input-border-color); /* Use CSS Variable */
  background-color: var(--input-background-color); /* Use CSS Variable */
  color: var(--input-text-color); /* Use CSS Variable */
  transition: border-color 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.personnel-data-container button {
  padding: 10px 20px;
  background-color: var(--button-background-color); /* Use CSS Variable */
  color: var(--button-text-color); /* Use CSS Variable */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.personnel-data-container button:hover {
  background-color: var(--button-hover-background-color); /* Use CSS Variable */
}
